import React from "react";

/*
This component implements scissors used in the rope levels.
Parts of it were created with the help of LLMs.
*/
function Scissors({ onClick, visibility }) {
  return (
    <button
      onClick={onClick}
      style={{
        cursor: visibility ? "pointer" : "",
        position: "absolute",
        top: "-3.5dvh",
        left: "100%",
        transform: "translateX(-50%)",
        background: "transparent",
        textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
        textBorder: "black",
        border: "none",
        fontSize: "2.5dvh",
        opacity: visibility,
      }}
    >
      ✂️
    </button>
  );
}

export default Scissors;
