import React, { useState, useEffect } from "react";
import coin1000 from "../images/newcoin1000.svg";
import coin100 from "../images/newcoin100.svg";
import coin10 from "../images/newcoin10.svg";
import coin1 from "../images/newcoin1.svg";
import ProgressPopup from "../components/ProgressPopup";
import {
  COLOR_HIGHLIGHTED,
  COLOR_LIGHT,
  COLOR_MEDIUM,
  COLOR_DIVISOR,
  FONT,
  increaseProgress,
  REPEATS,
} from "../defaults";
import InputBox from "../components/InputBox";
import {
  InputBoxCheck,
  InputBoxRemainder,
  level0Correct,
  level12action,
  level12FillFields,
  level2Welcome1,
  level2Welcome2,
  level3Incorrect1,
  nextLevelUnlocked,
} from "../strings";

const LevelNumber = 12;

function calculateDividend() {
  return Math.floor(Math.random() * 9000) + 1000;
}

function calculateDivisor() {
  return Math.floor(Math.random() * 8) + 2;
}

const coinImages = {
  0: coin1000,
  1: coin100,
  2: coin10,
  3: coin1,
};

function Coin({ coinImage }) {
  return (
    <div
      style={{
        width: "4dvh",
        height: "4dvh",
      }}
    >
      <img
        src={coinImage}
        alt="coin"
        style={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
        }}
      />
    </div>
  );
}

function Level12({ levelProgress, setLevelProgress }) {
  const [dividend, setDividend] = useState(calculateDividend);
  const [divisor, setDivisor] = useState(calculateDivisor);
  const [lineCount, setLineCount] = useState(1);
  const [inputsLeft, setInputsLeft] = useState(
    Array.from({ length: 8 }, () => Array.from({ length: 4 }, () => ""))
  );
  const [inputsRight, setInputsRight] = useState(
    Array.from({ length: 8 }, () => Array.from({ length: 4 }, () => ""))
  );
  const [solutionLeft, setSolutionLeft] = useState([]);
  const [solutionRight, setSolutionRight] = useState([]);
  const [solutionMiddle, setSolutionMiddle] = useState([]);
  const [highlightedInput, setHighlightedInput] = useState([]);
  const [lockedInputs, setLockedInputs] = useState([]);

  useEffect(() => {
    const rem = Array.from(dividend.toString(), Number);
    const sol = [0, 0, 0, 0];
    const div = divisor;
    let calcLeft = [];
    let calcRight = [];
    let calcMiddle = [];
    for (let i = 0; i < 4; i++) {
      if (rem[i] >= div) {
        calcMiddle = [
          ...calcMiddle,
          [0, rem[i], parseInt(rem[i] / div), rem[i] % div],
        ];
        sol[i] = parseInt(rem[i] / div);
        rem[i] = rem[i] % div;
        calcLeft = [...calcLeft, Array.from(rem)];
        calcRight = [...calcRight, Array.from(sol)];
      }
      if (rem[i] > 0 && i < 3) {
        calcMiddle = [...calcMiddle, [1, rem[i], i]];
        rem[i + 1] += rem[i] * 10;
        rem[i] = 0;
        calcLeft = [...calcLeft, Array.from(rem)];
        calcRight = [...calcRight, Array.from(sol)];
      }
    }
    setSolutionLeft(calcLeft);
    setSolutionRight(calcRight);
    setSolutionMiddle(calcMiddle);

    // Calculate and set the pre-filled fields
    let curLeft = Array.from(dividend.toString(), Number);
    let curRight = [0, 0, 0, 0];
    let newInputsLeft = Array.from({ length: 8 }, () =>
      Array.from({ length: 4 }, () => "")
    );
    let newInputsRight = Array.from({ length: 8 }, () =>
      Array.from({ length: 4 }, () => "")
    );
    let newLockedInputs = [];
    for (let i = 0; i < calcLeft.length; i++) {
      for (let j = 0; j < 4; j++) {
        if (calcLeft[i][j] === curLeft[j]) {
          newInputsLeft[i][j] = curLeft[j];
          newLockedInputs = [...newLockedInputs, [0, i, j]];
        }
        if (calcRight[i][j] === curRight[j]) {
          newInputsRight[i][j] = curRight[j];
          newLockedInputs = [...newLockedInputs, [1, i, j]];
        }
      }
      curLeft = calcLeft[i];
      curRight = calcRight[i];
    }
    setInputsLeft(newInputsLeft);
    setInputsRight(newInputsRight);
    setLockedInputs(newLockedInputs);
  }, [dividend, divisor]);

  useEffect(() => {
    setDividend(calculateDividend);
    setDivisor(calculateDivisor);
    setLineCount(1);
    setInputsLeft(
      Array.from({ length: 8 }, () => Array.from({ length: 4 }, () => ""))
    );
    setInputsRight(
      Array.from({ length: 8 }, () => Array.from({ length: 4 }, () => ""))
    );
  }, [levelProgress]);

  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCorrect = () => {
    if (levelProgress[LevelNumber] + 1 === REPEATS) {
      setMessage(level0Correct + nextLevelUnlocked);
    } else {
      setMessage(level0Correct);
    }
    setShowModal(true);
    increaseProgress(levelProgress, setLevelProgress, LevelNumber);
  };

  const handleIncorrect = () => {
    const inputsComplete =
      solutionLeft.length <= lineCount && solutionRight.length <= lineCount;
    setMessage(level3Incorrect1 + (inputsComplete ? "" : level12FillFields));
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleChangeLeft = (id, id2) => (e) => {
    const newInputs = [...inputsLeft];
    newInputs[id][id2] = e.target.value;
    setInputsLeft(newInputs);
  };

  const handleChangeRight = (id, id2) => (e) => {
    const newInputs = [...inputsRight];
    newInputs[id][id2] = e.target.value;
    setInputsRight(newInputs);
  };

  const checkLine = () => {
    let correct = true;
    let newHighlighted = [];
    for (let i = 0; i < 4; i++) {
      if (
        parseInt(inputsLeft[lineCount - 1][i]) !==
        parseInt(solutionLeft[lineCount - 1][i])
      ) {
        correct = false;
        const newInputs = [...inputsLeft];
        newInputs[lineCount - 1][i] = "";
        newHighlighted = [...newHighlighted, [0, lineCount - 1, i]];
        setInputsLeft(newInputs);
      }
      if (
        parseInt(inputsRight[lineCount - 1][i]) !==
        parseInt(solutionRight[lineCount - 1][i])
      ) {
        correct = false;
        const newInputs = [...inputsRight];
        newInputs[lineCount - 1][i] = "";
        newHighlighted = [...newHighlighted, [1, lineCount - 1, i]];
        setInputsRight(newInputs);
      }
    }
    if (correct) {
      setLineCount(lineCount + 1);
    }
    setHighlightedInput(newHighlighted);
    setTimeout(() => {
      setHighlightedInput([]);
    }, 4000);
  };

  const inputStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: FONT,
    color: "white",
    fontSize: "2.5dvh",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    width: "25%",
  };

  const getBorderStyle = (rowIndex, colIndex) => {
    let borderTop = rowIndex === 0 ? "0.25dvh solid black" : "none";
    let borderBottom = "0.25dvh solid black";
    let borderLeft = colIndex === 0 ? "0.25dvh solid black" : "none";
    let borderRight = "0.25dvh solid black";

    return {
      borderTop,
      borderBottom,
      borderLeft,
      borderRight,
    };
  };

  const boxSizedvh = "5dvh";
  const boxSize = 5;

  return (
    <div>
      <div
        className="Welcome-label"
        style={{ fontFamily: FONT, fontSize: "3.5dvh" }}
      >
        {level2Welcome1}
        {dividend}
        {level2Welcome2}
        <span style={{ color: COLOR_DIVISOR }}>{divisor}</span>?
      </div>
      <ProgressPopup show={showModal} handleClose={hideModal}>
        {message}
      </ProgressPopup>
      <div
        className="container"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div
          style={{
            display: "flex",
            height: `${
              (Math.min(lineCount, solutionLeft.length) + 2) * boxSize
            }dvh`,
            width: "50dvh",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: `${4 * boxSize}dvh`,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                height: boxSizedvh,
                width: `${4 * boxSize}dvh`,
              }}
            >
              {Array.from({ length: 4 }).map((_, id) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...getBorderStyle(0, id),
                    height: boxSizedvh,
                    width: boxSizedvh,
                  }}
                >
                  <Coin key={[id, 0]} id={[id, 0]} coinImage={coinImages[id]} />
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                height: boxSizedvh,
                width: `${4 * boxSize}dvh`,
              }}
            >
              {Array.from({ length: 4 }).map((_, id) => (
                <div style={{ ...inputStyle, ...getBorderStyle(0, id) }}>
                  {Array.from(dividend.toString())[id]}
                </div>
              ))}
            </div>
            {Array.from({
              length: Math.min(lineCount, solutionLeft.length),
            }).map((_, id) => (
              <div
                style={{
                  display: "flex",
                  height: boxSizedvh,
                  width: `${4 * boxSize}dvh`,
                }}
              >
                {Array.from({ length: 4 }).map((_, id2) => (
                  <input
                    title="Input"
                    type="number"
                    value={inputsLeft[id][id2]}
                    onChange={handleChangeLeft(id, id2)}
                    style={{
                      ...inputStyle,
                      ...getBorderStyle(id + 1, id2),
                      backgroundColor: highlightedInput.every(
                        (v) => v[0] !== 0 || v[1] !== id || v[2] !== id2
                      )
                        ? COLOR_MEDIUM
                        : COLOR_HIGHLIGHTED,
                    }}
                    placeholder=""
                    disabled={
                      id + 1 !== lineCount ||
                      lockedInputs.some(
                        (v) => v[0] === 0 && v[1] === id && v[2] === id2
                      )
                    }
                  ></input>
                ))}
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              height: boxSizedvh,
              alignItems: "center",
              fontFamily: FONT,
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            }}
          >
            <div>
              &nbsp; : <span style={{ color: COLOR_DIVISOR }}>{divisor}</span>
            </div>
          </div>
        </div>
        <div
          className="Aktion"
          style={{
            display: "flex",
            width: "30dvh",
            height: `${lineCount * boxSize}dvh`,
            flexDirection: "column",
            marginTop: `${boxSize / 2}dvh`,
          }}
        >
          <div
            style={{
              display: "flex",
              height: boxSizedvh,
              width: "30dvh",
              fontFamily: FONT,
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {level12action}
          </div>
          {Array.from({ length: lineCount - 1 }).map((_, id) => (
            <div>
              {solutionMiddle[id][0] === 0 && (
                <div
                  style={{
                    display: "flex",
                    height: boxSizedvh,
                    width: "30dvh",
                    fontFamily: FONT,
                    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {solutionMiddle[id][1]} :&nbsp;
                  <span style={{ color: COLOR_DIVISOR }}>
                    {divisor}
                  </span>&nbsp;= {solutionMiddle[id][2]} {InputBoxRemainder}{" "}
                  {solutionMiddle[id][3]}
                </div>
              )}
              {solutionMiddle[id][0] === 1 && (
                <div
                  style={{
                    display: "flex",
                    height: boxSizedvh,
                    width: "30dvh",
                    fontFamily: FONT,
                    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {solutionMiddle[id][1]} x&nbsp;
                  <Coin
                    key={[id, 2]}
                    id={[id, 2]}
                    coinImage={coinImages[solutionMiddle[id][2]]}
                  />
                  &nbsp;= {solutionMiddle[id][1] * 10} x&nbsp;
                  <Coin
                    key={[id, 3]}
                    id={[id, 3]}
                    coinImage={coinImages[solutionMiddle[id][2] + 1]}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            height: `${
              (Math.min(lineCount, solutionLeft.length) + 2) * boxSize
            }dvh`,
            width: "50dvh",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              fontFamily: FONT,
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
              height: boxSizedvh,
              alignItems: "center",
            }}
          >
            <span style={{ color: COLOR_DIVISOR }}>{divisor}</span>&nbsp;x&nbsp;
          </div>
          <div
            style={{
              display: "flex",
              width: `${4 * boxSize}dvh`,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                height: boxSizedvh,
                width: `${4 * boxSize}dvh`,
              }}
            >
              {Array.from({ length: 4 }).map((_, id) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...getBorderStyle(0, id),
                    height: boxSizedvh,
                    width: boxSizedvh,
                  }}
                >
                  <Coin key={[id, 1]} id={[id, 1]} coinImage={coinImages[id]} />
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                height: boxSizedvh,
                width: `${4 * boxSize}dvh`,
              }}
            >
              {Array.from({ length: 4 }).map((_, id) => (
                <div style={{ ...inputStyle, ...getBorderStyle(0, id) }}>0</div>
              ))}
            </div>
            {Array.from({
              length: Math.min(lineCount, solutionLeft.length),
            }).map((_, id) => (
              <div
                style={{
                  display: "flex",
                  height: boxSizedvh,
                  width: `${4 * boxSize}dvh`,
                }}
              >
                {Array.from({ length: 4 }).map((_, id2) => (
                  <input
                    title="Input"
                    type="number"
                    value={inputsRight[id][id2]}
                    onChange={handleChangeRight(id, id2)}
                    style={{
                      ...inputStyle,
                      ...getBorderStyle(id + 1, id2, lineCount, 4),
                      backgroundColor: highlightedInput.every(
                        (v) => v[0] !== 1 || v[1] !== id || v[2] !== id2
                      )
                        ? COLOR_MEDIUM
                        : COLOR_HIGHLIGHTED,
                    }}
                    placeholder=""
                    disabled={
                      id + 1 !== lineCount ||
                      lockedInputs.some(
                        (v) => v[0] === 1 && v[1] === id && v[2] === id2
                      )
                    }
                  ></input>
                ))}
              </div>
            ))}
          </div>
          {lineCount <= solutionLeft.length && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                fontFamily: FONT,
              }}
            >
              <button
                onClick={checkLine}
                style={{
                  marginLeft: "2dvh",
                  width: "12dvh",
                  padding: "1dvh",
                  fontFamily: FONT,
                  color: "white",
                  fontSize: "3dvh",
                  backgroundColor: COLOR_LIGHT,
                  borderRadius: "0.25em",
                  border: "none",
                  boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
                  textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
                }}
              >
                {" "}
                {InputBoxCheck}{" "}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="equation" style={{ padding: "0.5dvh" }}>
        <InputBox
          handleCorrect={handleCorrect}
          handleIncorrect={handleIncorrect}
          dividend={dividend}
          divisor={divisor}
          remainder={dividend % divisor}
        ></InputBox>
      </div>
    </div>
  );
}

export default Level12;
