import React, { useState, useEffect /*useCallback*/ } from "react";
import { NUMBER_OF_LEVELS, REPEATS } from "./defaults";
import Tutorial0 from "./tutorials/tutorial0";
import Level0 from "./levels/level0";
import Level1 from "./levels/level1";
import Level2 from "./levels/level2";
import Level3 from "./levels/level3";
import Level4 from "./levels/level4";
import Level5 from "./levels/level5";
import Level6 from "./levels/level6";
import Level7 from "./levels/level7";
import Level8 from "./levels/level8";
import Level9 from "./levels/level9";
import Level10 from "./levels/level10";
import Level11 from "./levels/level11";
import Level12 from "./levels/level12";
import Tutorial1 from "./tutorials/tutorial1";
import Tutorial2 from "./tutorials/tutorial2";
import Tutorial3 from "./tutorials/tutorial3";
import Tutorial4 from "./tutorials/tutorial4";
import Tutorial5 from "./tutorials/tutorial5";
import Tutorial6 from "./tutorials/tutorial6";
import Tutorial7 from "./tutorials/tutorial7";
import Tutorial8 from "./tutorials/tutorial8";
import Tutorial9 from "./tutorials/tutorial9";
import Tutorial10 from "./tutorials/tutorial10";
import Tutorial11 from "./tutorials/tutorial11";
import Tutorial12 from "./tutorials/tutorial12";

function Level({
  level,
  setLevel,
  progress,
  setProgress,
  levelProgress,
  setLevelProgress,
  tutorialProgress,
  setTutorialProgress,
}) {
  const [renderedComponent, setRenderedComponent] = useState(null);
  /*
  const increaseLevel = useCallback(() => {
    if (level === progress && progress+1 < NUMBER_OF_LEVELS) {
      setProgress(progress + 1);
    }

  }, [level, progress, setProgress]);

  const unlockAll = (() => {
    setProgress(NUMBER_OF_LEVELS-1);
  });

  const reset = (() => {
    setProgress(0);
    setLevel(0);
    const newProgress = [...levelProgress]
    const newTutorialProgress = [...tutorialProgress]
    for(let i=0; i<NUMBER_OF_LEVELS; i++){
      newProgress[i] = 0;
      newTutorialProgress[i] = 0;
    }
    setLevelProgress(newProgress);
    setTutorialProgress(newTutorialProgress);
  })
*/
  useEffect(() => {
    if (
      levelProgress[level] >= REPEATS &&
      level === progress &&
      progress + 1 < NUMBER_OF_LEVELS
    ) {
      setProgress(progress + 1);
    }
  }, [levelProgress, level, progress, setProgress]);

  useEffect(() => {
    const renderLevelComponent = () => {
      const tutorialDone = (tutorialLevel) => {
        const newProgress = [...tutorialProgress];
        newProgress[tutorialLevel] = 1;
        setTutorialProgress(newProgress);
      };
      switch (level) {
        case 0:
          return tutorialProgress[level] === 0 ? (
            <Tutorial0 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level0
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 1:
          return tutorialProgress[level] === 0 ? (
            <Tutorial1 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level1
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 2:
          return tutorialProgress[level] === 0 ? (
            <Tutorial2 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level2
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 3:
          return tutorialProgress[level] === 0 ? (
            <Tutorial3 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level3
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 4:
          return tutorialProgress[level] === 0 ? (
            <Tutorial4 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level4
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 5:
          return tutorialProgress[level] === 0 ? (
            <Tutorial5 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level5
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 6:
          return tutorialProgress[level] === 0 ? (
            <Tutorial6 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level6
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 7:
          return tutorialProgress[level] === 0 ? (
            <Tutorial7 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level7
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 8:
          return tutorialProgress[level] === 0 ? (
            <Tutorial8 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level8
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 9:
          return tutorialProgress[level] === 0 ? (
            <Tutorial9 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level9
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 10:
          return tutorialProgress[level] === 0 ? (
            <Tutorial10 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level10
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 11:
          return tutorialProgress[level] === 0 ? (
            <Tutorial11 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level11
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        case 12:
          return tutorialProgress[level] === 0 ? (
            <Tutorial12 finishTutorial={() => tutorialDone(level)} />
          ) : (
            <Level12
              levelProgress={levelProgress}
              setLevelProgress={setLevelProgress}
            />
          );
        default:
          return <div>Level {level} not implemented yet</div>;
      }
    };
    setRenderedComponent(renderLevelComponent());
  }, [
    level,
    levelProgress,
    setLevelProgress,
    progress,
    tutorialProgress,
    setTutorialProgress,
  ]);

  return (
    <div>
      {/*
          <button onClick={increaseLevel}>DEBUG: Unlock next</button>
          <button onClick={unlockAll}>DEBUG: Unlock all</button>
          <button onClick={reset}>DEBUG: Reset</button>*/}
      {renderedComponent}
    </div>
  );
}

export default Level;
