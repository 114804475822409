import React, { useState } from "react";
import {
  COLOR_DARK,
  COLOR_DIVISOR,
  COLOR_LIGHT,
  COLOR_MEDIUM,
  FONT,
} from "../defaults";
import { InputBoxResult, InputBoxRemainder, InputBoxCheck } from "../strings";
/*
This component implements a input box into which the divisor,
and optionally the remainder can be entered.
The check button only appears when the fields have been filled.
If the input is wrong, it gets reset and handleIncorrect gets called.
If it is correct, handleCorrect is called.
*/
function InputBox({
  handleCorrect,
  handleIncorrect,
  dividend,
  divisor,
  remainder,
  isLevel0,
}) {
  const [inputQuotient, setInputQuotient] = useState("");
  const [inputRemainder, setInputRemainder] = useState("");

  const handleQuotientChange = (e) => {
    if (isLevel0) {
      if (e.target.value < 10) {
        setInputQuotient(e.target.value);
      }
    } else if (
      dividend >= 1000 ? e.target.value < 10000 : e.target.value < 1000
    ) {
      setInputQuotient(e.target.value);
    }
  };

  const handleRemainderChange = (e) => {
    if (dividend >= 1000 ? e.target.value < 10000 : e.target.value < 1000) {
      setInputRemainder(e.target.value);
    }
  };

  const checkInput = () => {
    if (!remainder) {
      if (parseInt(inputQuotient) === dividend / divisor) {
        handleCorrect();
      } else {
        handleIncorrect();
      }
      setInputQuotient("");
      setInputRemainder("");
    } else {
      if (
        parseInt(inputQuotient) === (dividend - remainder) / divisor &&
        parseInt(inputRemainder) === remainder
      ) {
        handleCorrect();
        setInputQuotient("");
        setInputRemainder("");
      } else {
        handleIncorrect();
        if (parseInt(inputQuotient) === (dividend - remainder) / divisor) {
          setInputRemainder("");
        } else if (parseInt(inputRemainder) === remainder) {
          setInputQuotient("");
        } else {
          setInputQuotient("");
          setInputRemainder("");
        }
      }
    }
  };

  const inputStyle = {
    fontFamily: FONT,
    color: "white",
    fontSize: "3dvh",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    backgroundColor: COLOR_MEDIUM,
    borderRadius: "0.25em",
    boxShadow: "inset 0.3svh 0.3svh 0.6svh rgba(0, 0.5, 0, 0.5)",
    border: "none",
    textAlign: "center",
    width: dividend >= 1000 ? "8dvh" : "6dvh",
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <style>
        {`
              /* Hide arrows in input of type number for WebKit browsers (Chrome, Safari). This was generated by a LLM. */
              input[type="number"]::-webkit-inner-spin-button,
              input[type="number"]::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
              }

              /* Hide arrows in input of type number for Firefox */
              input[type="number"] {
                  -moz-appearance: textfield;
              }
            `}
      </style>
      <div
        className="result"
        style={{
          marginTop: "1dvh",
          fontFamily: FONT,
          color: "white",
          fontSize: "3dvh",
          textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
          display: "flex",
          backgroundColor: COLOR_DARK,
          borderRadius: "0.25em",
          boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1dvh",
          }}
        >
          {isLevel0 ? (
            <div>
              {InputBoxResult}: &nbsp;
              <input
                type="number"
                value={inputQuotient}
                max="9"
                placeholder=""
                onChange={handleQuotientChange}
                className="result-input"
                style={inputStyle}
              />
            </div>
          ) : (
            <div>
              {dividend} :&nbsp;
              <span style={{ color: COLOR_DIVISOR }}>{divisor}</span>&nbsp;=
              &nbsp;
              <input
                type="number"
                value={inputQuotient}
                placeholder=""
                onChange={handleQuotientChange}
                className="result-input"
                style={inputStyle}
              />
            </div>
          )}
        </div>
        {remainder != null && (
          <div
            style={{
              padding: "1dvh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {InputBoxRemainder}&nbsp;&nbsp;{" "}
            <input
              type="number"
              value={inputRemainder}
              placeholder=""
              onChange={handleRemainderChange}
              className="result-input"
              style={inputStyle}
            />
          </div>
        )}
      </div>
      <div style={{ height: "2dvh" }}></div>
      {inputQuotient !== "" && (!remainder || inputRemainder !== "") && (
        <button
          onClick={checkInput}
          className="button"
          style={{
            padding: "1dvh",
            fontFamily: FONT,
            color: "white",
            fontSize: "3dvh",
            backgroundColor: COLOR_LIGHT,
            borderRadius: "0.25em",
            border: "none",
            boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
          }}
        >
          {InputBoxCheck}
        </button>
      )}
    </div>
  );
}

export default InputBox;
