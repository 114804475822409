import React, { useState } from "react";
import "../App.css";
import ProgressPopup from "../components/ProgressPopup";
import { COLOR_LIGHT, FONT } from "../defaults";
import coinImage from "../images/coin1.svg";
import boxImage from "../images/chest.png";
import {
  Tutorial0Continue,
  Tutorial1Description1,
  Tutorial1Description2,
  Tutorial1Description3,
  Tutorial1Description4,
  Tutorial1Welcome,
} from "../strings";

function Coin() {
  return (
    <div
      style={{
        width: "5dvh",
        height: "5dvh",
      }}
    >
      <img
        src={coinImage}
        alt="coin"
        style={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
          zIndex: "2",
        }}
      />
    </div>
  );
}

function Tutorial1({ finishTutorial }) {
  const nBoxes = 3;
  const nCoins = 2;

  const [message] = useState(Tutorial1Welcome);
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [tutorialProgress, setTutorialProgress] = useState(0);
  const [showContinue, setShowContinue] = useState(false);

  const hideModal = () => {
    setShowModal(false);
    const fullDescription = Array.from(Tutorial1Description1);
    for (let i = 0; i <= fullDescription.length; i++) {
      setTimeout(() => {
        setDescription(fullDescription.slice(0, i));
      }, i * 50);
    }
    setTimeout(() => {
      setShowContinue(true);
    }, fullDescription.length * 50);
  };

  const continueProgress = () => {
    if (tutorialProgress === 0) {
      const nextDescription = Array.from(Tutorial1Description2);
      for (let i = 0; i <= nextDescription.length; i++) {
        setTimeout(() => {
          setDescription(nextDescription.slice(0, i));
        }, i * 50);
      }
      setTimeout(() => {
        setShowContinue(true);
      }, nextDescription.length * 50);
      setTutorialProgress(1);
    } else if (tutorialProgress === 1) {
      const nextDescription = Array.from(Tutorial1Description3);
      for (let i = 0; i <= nextDescription.length; i++) {
        setTimeout(() => {
          setDescription(nextDescription.slice(0, i));
        }, i * 50);
      }
      setTimeout(() => {
        setShowContinue(true);
      }, nextDescription.length * 50);
      setTutorialProgress(2);
    } else if (tutorialProgress === 2) {
      const nextDescription = Array.from(Tutorial1Description4);
      for (let i = 0; i <= nextDescription.length; i++) {
        setTimeout(() => {
          setDescription(nextDescription.slice(0, i));
        }, i * 50);
      }
      setTimeout(() => {
        setShowContinue(true);
      }, nextDescription.length * 50);
      setTutorialProgress(3);
    } else {
      finishTutorial();
    }
  };

  const boxSize = `calc(min(40vw / ${nBoxes}, 15vw))`;

  const handleClick = () => {
    setShowContinue(false);
    continueProgress();
  };

  return (
    <div style={{ margin: "1dvh" }}>
      <div
        className="Welcome-label"
        style={{
          fontFamily: FONT,
          fontSize: "4dvh",
          minHeight: "9dvh",
          whiteSpace: "pre-wrap",
        }}
      >
        {description}
      </div>
      <ProgressPopup
        show={showModal}
        handleClose={hideModal}
        showTutorialSkip={true}
        finishTutorial={finishTutorial}
      >
        {message}
      </ProgressPopup>
      <div style={{ marginTop: "1dvh" }}>
        <div
          style={{ display: "flex", justifyContent: "center", height: "7dvh" }}
        >
          {Array.from({ length: nCoins }).map((_, idx) => (
            <div key={idx}>
              <Coin key={idx} id={idx} style={{ zIndex: 10 }} />
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {Array.from({ length: nBoxes }).map((_, idx) => (
            <div
              key={idx}
              style={{
                width: boxSize,
                height: boxSize,
                backgroundColor: "none",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <img
                src={boxImage}
                alt="Box"
                style={{
                  position: "absolute",
                  width: boxSize,
                  height: boxSize,
                  zIndex: 0,
                  filter:
                    "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
                }}
              />
            </div>
          ))}
        </div>
      </div>
      {showContinue && (
        <button
          onClick={handleClick}
          className="button"
          style={{
            padding: "1dvh",
            fontFamily: FONT,
            color: "white",
            fontSize: "3dvh",
            backgroundColor: COLOR_LIGHT,
            borderRadius: "0.25em",
            border: "none",
            boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            marginTop: "2dvh",
            cursor: "pointer",
          }}
        >
          {Tutorial0Continue}
        </button>
      )}
    </div>
  );
}

export default Tutorial1;
