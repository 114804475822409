import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import coin100 from "../images/coin100.svg";
import coin10 from "../images/coin10.svg";
import coin1 from "../images/coin1.svg";
import InputBox from "../components/InputBox";
import ProgressPopup from "../components/ProgressPopup";
import { COLOR_LIGHT, FONT } from "../defaults";
import {
  level7Exchange,
  level7WrongDistributed,
  Tutorial0Continue,
  Tutorial0Description6,
  Tutorial8Description1,
  Tutorial8Description2,
  Tutorial7Description3,
  Tutorial7Description4,
  Tutorial7Description5,
  Tutorial8Welcome,
} from "../strings";

const coinImages = {
  0: coin100,
  1: coin10,
  2: coin1,
};

function Coin({ coinImage }) {
  return (
    <div
      style={{
        width: "5dvh",
        height: "5dvh",
      }}
    >
      <img
        src={coinImage}
        alt="coin"
        style={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
          zIndex: "2",
        }}
      />
    </div>
  );
}

function Tutorial8({ finishTutorial }) {
  const dividend = 233;
  const divisor = 2;
  const [coins, setCoins] = useState(Array.from(String(dividend), Number));
  const [droppedCoins, setDroppedCoins] = useState([0, 0, 0]);
  const [coinsInBoxes, setCoinsInBoxes] = useState(
    Array.from({ length: 2 }, () => [0, 0, 0])
  );
  const [tutorialProgress, setTutorialProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [modalMessage, setModalMessage] = useState(Tutorial8Welcome);
  const [showModal, setShowModal] = useState(true);
  const [showContine, setShowContinue] = useState(false);
  const [isMoved, setIsMoved] = useState([
    [false, false],
    [false, false, false],
    [false, false],
  ]);

  const distributeCoins = () => {
    setIsMoved([
      [false, true],
      [false, false, false],
      [false, false],
    ]);
    if (tutorialProgress < 3) {
      setTimeout(() => {
        setDroppedCoins([2, 0, 0]);
        setCoinsInBoxes([
          [1, 0, 0],
          [1, 0, 0],
        ]);
      }, 1000);
      setTimeout(() => {
        setIsMoved([
          [true, true],
          [false, false, true],
          [false, false],
        ]);
      }, 2000);
      setTimeout(() => {
        setDroppedCoins([2, 2, 0]);
        setCoinsInBoxes([
          [1, 1, 0],
          [1, 1, 0],
        ]);
        setShowContinue(true);
      }, 3000);
    } else {
      setTimeout(() => {
        setIsMoved([
          [],
          [],
          [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
          ],
        ]);
      }, 1000);
      setTimeout(() => {
        setIsMoved([
          [],
          [],
          [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            true,
            true,
          ],
        ]);
        setDroppedCoins([2, 2, 2]);
        setCoinsInBoxes([
          [1, 1, 1],
          [1, 1, 1],
        ]);
      }, 2000);
      setTimeout(() => {
        setIsMoved([
          [],
          [],
          [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            true,
            true,
            true,
            true,
          ],
        ]);
        setDroppedCoins([2, 2, 4]);
        setCoinsInBoxes([
          [1, 1, 2],
          [1, 1, 2],
        ]);
      }, 3000);
      setTimeout(() => {
        setIsMoved([
          [],
          [],
          [
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
          ],
        ]);
        setDroppedCoins([2, 2, 6]);
        setCoinsInBoxes([
          [1, 1, 3],
          [1, 1, 3],
        ]);
      }, 4000);
      setTimeout(() => {
        setIsMoved([
          [],
          [],
          [
            false,
            false,
            false,
            false,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
          ],
        ]);
        setDroppedCoins([2, 2, 8]);
        setCoinsInBoxes([
          [1, 1, 4],
          [1, 1, 4],
        ]);
      }, 5000);
      setTimeout(() => {
        setIsMoved([
          [],
          [],
          [
            false,
            false,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
          ],
        ]);
        setDroppedCoins([2, 2, 10]);
        setCoinsInBoxes([
          [1, 1, 5],
          [1, 1, 5],
        ]);
      }, 6000);
      setTimeout(() => {
        setDroppedCoins([2, 2, 12]);
        setCoinsInBoxes([
          [1, 1, 6],
          [1, 1, 6],
        ]);
        setShowContinue(true);
      }, 7000);
    }
  };

  const startText = () => {
    switch (tutorialProgress) {
      case 0:
        const description1 = Array.from(Tutorial8Description1);
        for (let i = 0; i <= description1.length; i++) {
          setTimeout(() => {
            setMessage(description1.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setShowContinue(true);
        }, description1.length * 50);
        setTutorialProgress(1);
        break;
      case 1:
        setShowContinue(false);
        const description2 = Array.from(Tutorial8Description2);
        for (let i = 0; i <= description2.length; i++) {
          setTimeout(() => {
            setMessage(description2.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          distributeCoins();
        }, description2.length * 50 + 1000);
        setTutorialProgress(2);
        break;
      case 2:
        setShowContinue(false);
        const description3 = Array.from(Tutorial7Description3);
        for (let i = 0; i <= description3.length; i++) {
          setTimeout(() => {
            setMessage(description3.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(3);
        }, description3.length * 50);
        break;
      case 3:
        setShowContinue(false);
        const description4 = Array.from(Tutorial7Description4);
        for (let i = 0; i <= description4.length; i++) {
          setTimeout(() => {
            setMessage(description4.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          distributeCoins();
        }, description4.length * 50);
        setTutorialProgress(4);
        break;
      case 4:
        setShowContinue(false);
        const description5 = Array.from(Tutorial7Description5);
        for (let i = 0; i <= description5.length; i++) {
          setTimeout(() => {
            setMessage(description5.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(5);
        }, description5.length * 50);
        break;
      default:
    }
  };

  function handleExchange(index) {
    const newCoins = [...coins];
    newCoins[index] = droppedCoins[index];
    newCoins[index + 1] += (coins[index] - droppedCoins[index]) * 10;
    setCoins(newCoins);
    setIsMoved([
      [],
      [],
      [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    ]);
    startText();
  }

  const handleCorrect = () => {
    setModalMessage(Tutorial0Description6);
    setShowModal(true);
    setTutorialProgress(10);
  };

  const handleIncorrect = () => {
    setModalMessage(level7WrongDistributed);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
    if (tutorialProgress === 0) {
      startText();
    } else if (tutorialProgress === 10) {
      finishTutorial();
    }
  };

  const [boxPositions, setBoxPositions] = useState([]);
  const boxRefs = useRef([]);
  const [coinPositions, setCoinPositions] = useState([]);
  const coinRefs = useRef([]);

  useEffect(() => {
    const positions = boxRefs.current.map((box) => {
      const rect = box.getBoundingClientRect();
      return { x: rect.left + rect.width / 8, y: rect.top + rect.height / 2 };
    });
    setBoxPositions(positions);

    const coinPos = Object.entries(coinRefs.current).map(([key, coin]) => {
      if (coin) {
        const rect = coin.getBoundingClientRect();
        return {
          x: rect.left + window.innerHeight / 40,
          y: rect.top + window.innerHeight / 40,
        };
      }
      return null;
    });
    setCoinPositions(coinPos);
  }, [droppedCoins, coins]);

  const exchangeStyle = {
    padding: "0.5dvh",
    margin: "0.5dvh",
    width: "10dvh",
    fontFamily: FONT,
    color: "white",
    fontSize: "2dvh",
    backgroundColor: COLOR_LIGHT,
    borderRadius: "0.25em",
    border: "none",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  const coinsStyle = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    maxHeight: "50dvh",
  };

  const coinStyle = {
    width: "4dvh",
    height: "4dvh",
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
  };

  const boxStyle = {
    display: "flex",
    flexWrap: "wrap",
    width: "30dvh",
    height: "9dvh",
    zIndex: 0,
    padding: "0.5dvh",
    filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
    borderRadius: "0.25em",
    alignItems: "center",
    backgroundColor: COLOR_LIGHT,
  };

  const dropStyle = {
    width: "30dvh",
    height: "11dvh",
    backgroundColor: "none",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
  };

  return (
    <div>
      <div
        className="Welcome-label"
        style={{
          fontFamily: FONT,
          fontSize: "3.5dvh",
          minHeight: "6dvh",
          whiteSpace: "pre-wrap",
        }}
      >
        {message}
      </div>
      <ProgressPopup
        show={showModal}
        handleClose={hideModal}
        showTutorialSkip={tutorialProgress === 0}
        finishTutorial={finishTutorial}
      >
        {modalMessage}
      </ProgressPopup>
      <div
        className="container"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "40dvh",
            width: "30dvh",
          }}
        >
          {Array.from({ length: 3 }).map((_, id) => (
            <div
              key={id}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <button
                disabled={
                  coins[id] === droppedCoins[id] ||
                  id !== 1 ||
                  tutorialProgress !== 3
                }
                onClick={() => handleExchange(id)}
                style={{
                  ...exchangeStyle,
                  opacity: coins[id] === droppedCoins[id] ? 0 : 1,
                  cursor:
                    id !== 1 || tutorialProgress !== 3 ? "auto" : "pointer",
                }}
              >
                {level7Exchange}
              </button>
              <div style={coinsStyle}>
                {Array.from({ length: coins[id] - droppedCoins[id] }).map(
                  (_, idx) => (
                    <motion.div
                      key={[id, idx]}
                      ref={(el) => (coinRefs.current[[id, idx]] = el)}
                      animate={{
                        x:
                          isMoved[id][idx] &&
                          boxPositions[(1 + idx + (id > 0)) % divisor] &&
                          coinPositions[
                            idx +
                              ((id > 0) * coins[0] + (id > 1) * (coins[1] + 1))
                          ]
                            ? boxPositions[(1 + idx + (id > 0)) % divisor].x -
                              coinPositions[
                                idx +
                                  ((id > 0) * coins[0] +
                                    (id > 1) * (coins[1] + 1))
                              ].x
                            : 0,
                        y:
                          isMoved[id][idx] &&
                          boxPositions[(1 + idx + (id > 0)) % divisor] &&
                          coinPositions[
                            idx +
                              ((id > 0) * coins[0] + (id > 1) * (coins[1] + 1))
                          ]
                            ? boxPositions[(1 + idx + (id > 0)) % divisor].y -
                              coinPositions[
                                idx +
                                  ((id > 0) * coins[0] +
                                    (id > 1) * (coins[1] + 1))
                              ].y
                            : 0,
                      }}
                      transition={{ duration: 1 }}
                      style={{ zIndex: 1 }}
                    >
                      <Coin
                        key={[id, idx]}
                        id={[id, idx]}
                        coinImage={coinImages[id]}
                      />
                    </motion.div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="right-panel">
          {Array.from({ length: divisor }).map((_, idx) => (
            <div
              key={idx}
              ref={(el) => (boxRefs.current[idx] = el)}
              style={dropStyle}
            >
              <div alt="Box" style={boxStyle}>
                {Array.from({ length: 3 }, (_, id) =>
                  Array.from({ length: coinsInBoxes[idx][id] }).map(
                    (_, coinId) => (
                      <div key={coinId} style={coinStyle}>
                        <img src={coinImages[id]} alt="coin" />
                      </div>
                    )
                  )
                )}
              </div>
            </div>
          ))}
        </div>
        {tutorialProgress === 5 ? (
          <div className="equation" style={{ padding: "0.5dvh" }}>
            <InputBox
              handleCorrect={handleCorrect}
              handleIncorrect={handleIncorrect}
              dividend={dividend}
              divisor={divisor}
              remainder={dividend % divisor}
            ></InputBox>
          </div>
        ) : (
          <div style={{ width: "22dvh" }}></div>
        )}
      </div>
      {showContine && (
        <button
          onClick={startText}
          className="button"
          style={{
            padding: "1dvh",
            fontFamily: FONT,
            color: "white",
            fontSize: "3dvh",
            backgroundColor: COLOR_LIGHT,
            borderRadius: "0.25em",
            border: "none",
            boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            marginTop: "2dvh",
            cursor: "pointer",
          }}
        >
          {Tutorial0Continue}
        </button>
      )}
    </div>
  );
}

export default Tutorial8;
