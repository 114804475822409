import React from "react";
import { COLOR_DARK, COLOR_LIGHT, FONT } from "../defaults";
import { ProgressPopupOkay, ProgressPopupSkip } from "../strings";

/*
This component implements a popup which displays the message specified in "children".
handleClose has to be provided to allow it to close.
Parts of it were created with the help of LLMs.
*/

const ProgressPopup = ({
  handleClose,
  show,
  children,
  showTutorialSkip,
  finishTutorial,
}) => {
  const popupmain = {
    background: COLOR_DARK,
    padding: "6dvh",
    width: "100%",
    height: "100%",
  };

  const popup = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundcolor: "rgba(0, 0, 0, 0.5)",
    justifycontent: "center",
    alignitems: "center",
    display: show ? "flex" : "none",
    zIndex: "2",
    fontFamily: FONT,
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
  };

  // This should be fine since "children" is never user generated
  const formattedText = children.replace(/\n/g, "<br>");

  return (
    <div style={popup}>
      <section className="popup-main" style={popupmain}>
        <h1 dangerouslySetInnerHTML={{ __html: formattedText }}></h1>
        <button
          onClick={handleClose}
          className="button"
          style={{
            margin: "1dvh",
            padding: "1dvh",
            fontFamily: FONT,
            color: "white",
            fontSize: "3dvh",
            backgroundColor: COLOR_LIGHT,
            borderRadius: "0.25em",
            border: "none",
            boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
          }}
        >
          {ProgressPopupOkay}
        </button>
        <div></div>
        {showTutorialSkip && (
          <button
            onClick={finishTutorial}
            className="button"
            style={{
              margin: "2dvh",
              padding: "1dvh",
              fontFamily: FONT,
              color: "white",
              fontSize: "3dvh",
              backgroundColor: COLOR_LIGHT,
              borderRadius: "0.25em",
              border: "none",
              boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            }}
          >
            {ProgressPopupSkip}
          </button>
        )}
      </section>
    </div>
  );
};

export default ProgressPopup;
