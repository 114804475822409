import React, { useState, useEffect } from "react";
import coin100 from "../images/coin100.svg";
import coin10 from "../images/coin10.svg";
import coin1 from "../images/coin1.svg";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import Box from "../components/Box";
import Coin from "../components/Coin";
import InputBox from "../components/InputBox";
import ProgressPopup from "../components/ProgressPopup";
import {
  COLOR_DIVISOR,
  COLOR_LIGHT,
  FONT,
  increaseProgress,
  REPEATS,
} from "../defaults";
import {
  level0Correct,
  level2Welcome1,
  level2Welcome2,
  level7BiggerFirst,
  level7Exchange,
  level7Exchange1,
  level7ExchangeCoins,
  level7NotEnoughCoins,
  level7TooMany,
  level9Welcome,
  level7WrongDistributed,
  level7WrongNotDistributed,
  nextLevelUnlocked,
} from "../strings";

const LevelNumber = 9;

function calculateDividend() {
  return Math.floor(Math.random() * 900) + 100;
}

function calculateDivisor() {
  return Math.floor(Math.random() * 4) + 2;
}

const coinImages = {
  0: coin100,
  1: coin10,
  2: coin1,
};

function Level9({ levelProgress, setLevelProgress }) {
  const [dividend, setDividend] = useState(calculateDividend());
  const [divisor, setDivisor] = useState(calculateDivisor());
  const [coins, setCoins] = useState([]);
  const [droppedCoins, setDroppedCoins] = useState([0, 0, 0]);
  const [coinsInBoxes, setCoinsInBoxes] = useState([0, 0, 0]);

  useEffect(() => {
    setDividend(calculateDividend());
    setDivisor(calculateDivisor());
  }, [levelProgress]);

  useEffect(() => {
    setCoins(Array.from(String(dividend), Number));
    setDroppedCoins([0, 0, 0]);
    setCoinsInBoxes([0, 0, 0]);
  }, [dividend, divisor]);

  const handleDropCoin = (item) => {
    if (coins[item[0]] - droppedCoins[item[0]] < divisor && item[0] === 2) {
      setMessage(level7NotEnoughCoins);
      setShowModal(true);
    } else if (coins[item[0]] - droppedCoins[item[0]] < divisor) {
      setMessage(level7ExchangeCoins);
      setShowModal(true);
    } else {
      const newDroppedCoins = [...droppedCoins];
      newDroppedCoins[item[0]] += divisor;
      setDroppedCoins(newDroppedCoins);
      const newCoinsInBoxes = [...coinsInBoxes];
      newCoinsInBoxes[item[0]] += 1;
      setCoinsInBoxes(newCoinsInBoxes);
    }
  };

  function handleExchange(index) {
    if (index === 2) {
      setMessage(level7Exchange1);
      setShowModal(true);
    } else if (index === 1 && coins[0] !== droppedCoins[0]) {
      setMessage(level7BiggerFirst);
      setShowModal(true);
    } else if (coins[index] - droppedCoins[index] < divisor) {
      const newCoins = [...coins];
      newCoins[index] = droppedCoins[index];
      newCoins[index + 1] += (coins[index] - droppedCoins[index]) * 10;
      setCoins(newCoins);
    } else {
      setMessage(level7TooMany);
      setShowModal(true);
    }
  }

  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCorrect = () => {
    if (levelProgress[LevelNumber] + 1 === REPEATS) {
      setMessage(level0Correct + nextLevelUnlocked);
    } else {
      setMessage(level0Correct);
    }
    setShowModal(true);
    increaseProgress(levelProgress, setLevelProgress, LevelNumber);
  };

  const handleIncorrect = () => {
    const boxesFull =
      coins[0] === droppedCoins[0] &&
      coins[1] === droppedCoins[1] &&
      coins[2] === droppedCoins[2] + (dividend % divisor);
    if (boxesFull) {
      setMessage(level7WrongDistributed);
    } else {
      setMessage(level7WrongNotDistributed);
    }
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const exchangeStyle = {
    padding: "0.5dvh",
    margin: "0.5dvh",
    width: "10dvh",
    fontFamily: FONT,
    color: "white",
    fontSize: "2dvh",
    backgroundColor: COLOR_LIGHT,
    borderRadius: "0.25em",
    border: "none",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
  };

  const coinsStyle = {
    display: "flex",
    alignItems: "center",
    maxHeight: "65dvh",
    fontFamily: FONT,
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
  };

  return (
    <div>
      <div
        className="Welcome-label"
        style={{ fontFamily: FONT, fontSize: "3.5dvh" }}
      >
        {level2Welcome1}
        {dividend}
        {level2Welcome2}
        <span style={{ color: COLOR_DIVISOR }}>{divisor}</span>?<br></br>
        {level9Welcome}
      </div>
      <ProgressPopup show={showModal} handleClose={hideModal}>
        {message}
      </ProgressPopup>
      <DndProvider options={HTML5toTouch}>
        <div
          className="container"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              height: "50dvh",
              width: "34dvh",
            }}
          >
            {Array.from({ length: 3 }).map((_, id) => (
              <React.Fragment key={id}>
                {(coins[id] - droppedCoins[id] > 0 ||
                  (id > 0 && coins[0] - droppedCoins[0] > 0) ||
                  (id > 1 && coins[1] - droppedCoins[1] > 0)) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "13dvh",
                    }}
                  >
                    <button
                      disabled={coins[id] === droppedCoins[id]}
                      onClick={() => handleExchange(id)}
                      style={{
                        ...exchangeStyle,
                        opacity: coins[id] === droppedCoins[id] ? 0 : 1,
                      }}
                    >
                      {level7Exchange}
                    </button>
                    <div style={coinsStyle}>
                      {coins[id] - droppedCoins[id]} x&nbsp;
                      <Coin
                        key={[id, 0]}
                        id={[id, 0]}
                        coinImage={coinImages[id]}
                      />
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
          <div
            className="right-panel"
            style={{
              display: "flex",
              maxHeight: "11.5dvh",
              width: "36dvh",
              alignItems: "center",
              fontFamily: FONT,
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            }}
          >
            <span style={{ color: COLOR_DIVISOR }}>{divisor}</span>&nbsp;x&nbsp;
            <Box
              id={0}
              onDropCoin={handleDropCoin}
              coins={coinsInBoxes}
              groupCoins={true}
            />
          </div>
          <div
            className="equation"
            style={{ padding: "0.5dvh", width: "34dvh" }}
          >
            <InputBox
              handleCorrect={handleCorrect}
              handleIncorrect={handleIncorrect}
              dividend={dividend}
              divisor={divisor}
              remainder={dividend % divisor}
            ></InputBox>
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

export default Level9;
