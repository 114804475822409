import ropeImage from "../images/rope2.png";
import React from "react";

/*
This component implements a single rope piece.
With "falling", we can "drop" the rope by "fallHeight"dvh.
Parts of it were created with the help of LLMs.
*/
function RopePiece({ pieceSize, isFalling, fallHeight, showDot }) {
  const pieces = Array.from({ length: pieceSize }, (_, index) => (
    <div
      key={index}
      style={{
        width: `5dvh`,
        height: "5dvh",
        marginTop: isFalling ? `${fallHeight}dvh` : "0",
        transition: "margin-top 0.5s ease-in-out",
      }}
    >
      <img
        src={ropeImage}
        alt="rope"
        style={{ width: "100%", height: "100%" }}
      />
      {showDot && (
        <div
          style={{
            position: "relative",
            top: "-50%",
            left: "50%",
            transform: "translate(-50%, -100%)",
            width: "1.5dvh",
            height: "1.5dvh",
            backgroundColor: "red",
            borderRadius: "50%",
          }}
        ></div>
      )}
    </div>
  ));

  return <div style={{ display: "flex", alignItems: "center" }}>{pieces}</div>;
}

export default RopePiece;
