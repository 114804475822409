import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import coin100 from "../images/coin100.svg";
import coin10 from "../images/coin10.svg";
import coin1 from "../images/coin1.svg";
import InputBox from "../components/InputBox";
import ProgressPopup from "../components/ProgressPopup";
import { COLOR_DIVISOR, COLOR_LIGHT, FONT } from "../defaults";
import {
  level7Exchange,
  level7WrongDistributed,
  Tutorial0Continue,
  Tutorial0Description6,
  Tutorial10Description1,
  Tutorial10Description2,
  Tutorial10Description3,
  Tutorial10Description4,
  Tutorial10Description5,
  Tutorial10Description6,
  Tutorial10Welcome,
} from "../strings";

const coinImages = {
  0: coin100,
  1: coin10,
  2: coin1,
};

function Coin({ coinImage }) {
  return (
    <div
      style={{
        width: "5dvh",
        height: "5dvh",
      }}
    >
      <img
        src={coinImage}
        alt="coin"
        style={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
          zIndex: "2",
        }}
      />
    </div>
  );
}

function Tutorial10({ finishTutorial }) {
  const dividend = 233;
  const divisor = 21;
  const [coins, setCoins] = useState(Array.from(String(dividend), Number));
  const [droppedCoins, setDroppedCoins] = useState([0, 0, 0]);
  const [tutorialProgress, setTutorialProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [modalMessage, setModalMessage] = useState(Tutorial10Welcome);
  const [showModal, setShowModal] = useState(true);
  const [showContine, setShowContinue] = useState(false);
  const [isMoved, setIsMoved] = useState([
    [false, false],
    [false, false],
    [false, false],
  ]);

  const distributeCoins = () => {
    if (tutorialProgress < 3) {
      setIsMoved([
        [false, false],
        [false, true],
        [false, false],
      ]);
      setTimeout(() => {
        setIsMoved([
          [false, false],
          [true, true],
          [false, false],
        ]);
      }, 1000);
      setTimeout(() => {
        setIsMoved([
          [false, false],
          [false, false],
          [false, false],
        ]);
        setDroppedCoins([0, 21, 0]);
        setShowContinue(true);
      }, 2000);
    } else {
      setIsMoved([
        [false, false],
        [false, false],
        [false, true],
      ]);
      setTimeout(() => {
        setIsMoved([
          [false, false],
          [false, false],
          [true, true],
        ]);
      }, 1000);
      setTimeout(() => {
        setIsMoved([
          [false, false],
          [false, false],
          [false, false],
        ]);
        setDroppedCoins([0, 21, 21]);
        setShowContinue(true);
      }, 2000);
    }
  };

  const startText = () => {
    switch (tutorialProgress) {
      case 0:
        const description1 = Array.from(Tutorial10Description1);
        for (let i = 0; i <= description1.length; i++) {
          setTimeout(() => {
            setMessage(description1.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setShowContinue(true);
        }, description1.length * 50);
        setTutorialProgress(1);
        break;
      case 1:
        setShowContinue(false);
        const description2 = Array.from(Tutorial10Description2);
        for (let i = 0; i <= description2.length; i++) {
          setTimeout(() => {
            setMessage(description2.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(2);
        }, description2.length * 50);
        break;
      case 2:
        setShowContinue(false);
        const description3 = Array.from(Tutorial10Description3);
        for (let i = 0; i <= description3.length; i++) {
          setTimeout(() => {
            setMessage(description3.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          distributeCoins();
          setTutorialProgress(3);
        }, description3.length * 50);
        break;
      case 3:
        setShowContinue(false);
        const description4 = Array.from(Tutorial10Description4);
        for (let i = 0; i <= description4.length; i++) {
          setTimeout(() => {
            setMessage(description4.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(4);
        }, description4.length * 50);
        break;
      case 4:
        setShowContinue(false);
        const description5 = Array.from(Tutorial10Description5);
        for (let i = 0; i <= description5.length; i++) {
          setTimeout(() => {
            setMessage(description5.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          distributeCoins();
          setTutorialProgress(5);
        }, description5.length * 50);
        break;
      case 5:
        setShowContinue(false);
        const description6 = Array.from(Tutorial10Description6);
        for (let i = 0; i <= description6.length; i++) {
          setTimeout(() => {
            setMessage(description6.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(6);
        }, description6.length * 50);
        break;
      default:
    }
  };

  function handleExchange(index) {
    const newCoins = [...coins];
    newCoins[index] = droppedCoins[index];
    newCoins[index + 1] += (coins[index] - droppedCoins[index]) * 10;
    setCoins(newCoins);
    startText();
  }

  const handleCorrect = () => {
    setModalMessage(Tutorial0Description6);
    setShowModal(true);
    setTutorialProgress(10);
  };

  const handleIncorrect = () => {
    setModalMessage(level7WrongDistributed);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
    if (tutorialProgress === 0) {
      startText();
    } else if (tutorialProgress === 10) {
      finishTutorial();
    }
  };

  const [boxPositions, setBoxPositions] = useState([]);
  const boxRefs = useRef([]);
  const [coinPositions, setCoinPositions] = useState([]);
  const coinRefs = useRef([]);

  useEffect(() => {
    const positions = boxRefs.current.map((box) => {
      const rect = box.getBoundingClientRect();
      return { x: rect.left + rect.width / 8, y: rect.top + rect.height / 2 };
    });
    setBoxPositions(positions);

    const coinPos = Object.entries(coinRefs.current).map(([key, coin]) => {
      if (coin) {
        const rect = coin.getBoundingClientRect();
        return {
          x: rect.left + window.innerHeight / 40,
          y: rect.top + window.innerHeight / 40,
        };
      }
      return null;
    });
    setCoinPositions(coinPos);
  }, []);

  const exchangeStyle = {
    padding: "0.5dvh",
    margin: "0.5dvh",
    width: "10dvh",
    fontFamily: FONT,
    color: "white",
    fontSize: "2dvh",
    backgroundColor: COLOR_LIGHT,
    borderRadius: "0.25em",
    border: "none",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  const coinsStyle = {
    display: "flex",
    alignItems: "center",
    maxHeight: "40dvh",
    fontFamily: FONT,
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
  };

  const groupedCoinStyle = {
    width: "10dvh",
    height: "4dvh",
    display: "flex",
    justifyContent: "center",
    fontStyle: FONT,
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  };

  const boxStyle = {
    display: "flex",
    flexWrap: "wrap",
    width: "30dvh",
    height: "9dvh",
    zIndex: 0,
    padding: "0.5dvh",
    filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
    borderRadius: "0.25em",
    alignItems: "center",
    backgroundColor: COLOR_LIGHT,
  };

  return (
    <div>
      <div
        className="Welcome-label"
        style={{
          fontFamily: FONT,
          fontSize: "3.5dvh",
          minHeight: "6dvh",
          whiteSpace: "pre-wrap",
        }}
      >
        {message}
      </div>
      <ProgressPopup
        show={showModal}
        handleClose={hideModal}
        showTutorialSkip={tutorialProgress === 0}
        finishTutorial={finishTutorial}
      >
        {modalMessage}
      </ProgressPopup>
      <div
        className="container"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: "40dvh",
            width: "34dvh",
          }}
        >
          {Array.from({ length: 3 }).map((_, id) => (
            <React.Fragment key={id}>
              {(coins[id] - droppedCoins[id] > 0 ||
                (id > 0 && coins[0] - droppedCoins[0] > 0) ||
                (id > 1 && coins[1] - droppedCoins[1] > 0)) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "13dvh",
                  }}
                >
                  <button
                    disabled={
                      coins[id] === droppedCoins[id] ||
                      (!(id === 1 && tutorialProgress === 4) &&
                        !(id === 0 && tutorialProgress === 2))
                    }
                    onClick={() => handleExchange(id)}
                    style={{
                      ...exchangeStyle,
                      opacity: coins[id] === droppedCoins[id] ? 0 : 1,
                      cursor:
                        coins[id] === droppedCoins[id] ||
                        (!(id === 1 && tutorialProgress === 4) &&
                          !(id === 0 && tutorialProgress === 2))
                          ? "auto"
                          : "pointer",
                    }}
                  >
                    {level7Exchange}
                  </button>
                  <div style={coinsStyle}>
                    {coins[id] - droppedCoins[id]} x&nbsp;
                    <div style={{ display: "flex", position: "relative" }}>
                      <div style={{ zIndex: 0, position: "absolute" }}>
                        <Coin key={id} id={id} coinImage={coinImages[id]} />
                      </div>
                      <motion.div
                        key={id}
                        ref={(el) => (coinRefs.current[id] = el)}
                        animate={{
                          x:
                            isMoved[id][0] &&
                            boxPositions[0] &&
                            coinPositions[id]
                              ? boxPositions[0].x - coinPositions[id].x
                              : 0,
                          y:
                            isMoved[id][0] &&
                            boxPositions[0] &&
                            coinPositions[id]
                              ? boxPositions[0].y - coinPositions[id].y
                              : 0,
                        }}
                        transition={{ duration: 1 }}
                        style={{
                          zIndex: 1,
                          position: "relative",
                          visibility: isMoved[id][1] ? "visible" : "hidden",
                        }}
                      >
                        <Coin
                          key={id + "1"}
                          id={id + "1"}
                          coinImage={coinImages[id]}
                          style={{ opacity: isMoved[id][1] }}
                        />
                      </motion.div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div
          className="right-panel"
          style={{
            display: "flex",
            maxHeight: "11.5dvh",
            width: "36dvh",
            alignItems: "center",
            fontFamily: FONT,
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
          }}
        >
          <span style={{ color: COLOR_DIVISOR }}>{divisor}</span>&nbsp;x&nbsp;
          <div ref={(el) => (boxRefs.current[0] = el)}>
            <div alt="Box" style={boxStyle}>
              {Array.from({ length: 3 }, (_, id) => (
                <div key={id} style={groupedCoinStyle}>
                  {droppedCoins[id] / divisor} x&nbsp;
                  <img src={coinImages[id]} alt="coin" />
                </div>
              ))}
            </div>
          </div>
        </div>
        {tutorialProgress === 6 ? (
          <div
            className="equation"
            style={{ padding: "0.5dvh", width: "34dvh" }}
          >
            <InputBox
              handleCorrect={handleCorrect}
              handleIncorrect={handleIncorrect}
              dividend={dividend}
              divisor={divisor}
              remainder={dividend % divisor}
            ></InputBox>
          </div>
        ) : (
          <div style={{ width: "34dvh" }}></div>
        )}
      </div>
      {showContine && (
        <button
          onClick={startText}
          className="button"
          style={{
            padding: "1dvh",
            fontFamily: FONT,
            color: "white",
            fontSize: "3dvh",
            backgroundColor: COLOR_LIGHT,
            borderRadius: "0.25em",
            border: "none",
            boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            marginTop: "2dvh",
            cursor: "pointer",
          }}
        >
          {Tutorial0Continue}
        </button>
      )}
    </div>
  );
}

export default Tutorial10;
