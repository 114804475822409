import React from "react";
import { useDrop } from "react-dnd";
import coinImage from "../images/coin1.svg";
import boxImage from "../images/chest.png";

/*
This component implements a chest into which coins can be dropped.
Parts of it were created with the help of LLMs.
*/

function Box({ id, maxCoins, nBoxes, coins, onDropCoin }) {
  const [, drop] = useDrop({
    accept: "coin",
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        onDropCoin(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const boxSize = `calc(min(40vw / ${nBoxes}, 15vw))`;

  return (
    <div
      ref={drop}
      id={id}
      style={{
        width: boxSize,
        height: boxSize,
        backgroundColor: "none",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <img
        src={boxImage}
        alt="Box"
        style={{
          position: "absolute",
          width: boxSize,
          height: boxSize,
          zIndex: 0,
          filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
        }}
      />
      {coins.map((coinId) => (
        <div
          key={coinId}
          style={{
            marginTop: "7dvh",
            width: `calc(max(${80 / maxCoins}%,20%))`,
            height: `calc(max(${80 / maxCoins}%,20%))`,
            display: "flex",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <img
            src={coinImage}
            alt="coin"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      ))}
    </div>
  );
}

export default Box;
