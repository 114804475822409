export const COLOR_VERYLIGHT = "#D5F0C1";
export const COLOR_LIGHT = "#AAD9BB";
export const COLOR_MEDIUM = "#80BCBD";
export const COLOR_DARK = "#003F3F";
export const COLOR_HIGHLIGHTED = "#F53030";
export const COLOR_DIVISOR = "#FFC0C0";
export const FONT = "Merriweather Sans, sans-serif";
export const REPEATS = 3;
export const NUMBER_OF_LEVELS = 13;
export const increaseProgress = (levelProgress, setLevelProgress, level) => {
  const newProgress = [...levelProgress];
  newProgress[level] += 1;
  setLevelProgress(newProgress);
};
