import React, { useEffect, useState, useCallback } from "react";
import "../App.css";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import Coin from "../components/Coin";
import Box from "../components/Chest";
import ProgressPopup from "../components/ProgressPopup";
import { FONT, increaseProgress, REPEATS } from "../defaults";
import InputBox from "../components/InputBox";
import {
  level0Correct,
  level0Incorrect,
  level0Question,
  level0Welcome,
  level0WrongChest,
  nextLevelUnlocked,
} from "../strings";

const LevelNumber = 0;

function calculateInitialBoxes(currentProgress) {
  const numbers = [2, 3, 4];
  if (currentProgress >= numbers.length) {
    return Math.ceil(Math.random() * 4) + 1;
  }
  return numbers[currentProgress];
}

function calculateInitialCoins(initialBoxes, currentProgress) {
  const numbers = [2, 3, 2];
  if (currentProgress >= numbers.length) {
    return (Math.ceil(Math.random() * (6 - initialBoxes)) + 1) * initialBoxes;
  }
  return numbers[currentProgress] * initialBoxes;
}

function Level0({ levelProgress, setLevelProgress }) {
  const [nBoxes, setNBoxes] = useState(
    calculateInitialBoxes(levelProgress[LevelNumber])
  );
  const [nCoins, setNCoins] = useState(
    calculateInitialCoins(nBoxes, levelProgress[LevelNumber])
  );

  const [droppedCoins, setDroppedCoins] = useState(new Set());
  const [coinsInBoxes, setCoinsInBoxes] = useState(Array(nBoxes).fill([]));

  const [boxToFill, setBoxToFill] = useState(0);
  const [boxesFilled, setBoxesFilled] = useState(false);

  useEffect(() => {
    const numbers = calculateInitialBoxes(levelProgress[LevelNumber]);
    setNBoxes(numbers);
    setNCoins(calculateInitialCoins(numbers, levelProgress[LevelNumber]));
    setDroppedCoins(new Set());
    setCoinsInBoxes(Array(numbers).fill([]));
    setBoxesFilled(false);
  }, [levelProgress]);

  useEffect(() => {
    const expectedNumber = Math.floor(nCoins / nBoxes);
    const allBoxesFilled = coinsInBoxes.every(
      (box) => box.length === expectedNumber
    );
    if (allBoxesFilled) setBoxesFilled(true);
  }, [coinsInBoxes, nCoins, nBoxes]);

  const handleDropCoin = (item, boxIndex) => {
    if (boxIndex === boxToFill) {
      setDroppedCoins((prev) => new Set(prev).add(item.id));
      setCoinsInBoxes((prev) => {
        const newCoinsInBoxes = [...prev];
        newCoinsInBoxes[boxIndex] = [...newCoinsInBoxes[boxIndex], item.id];
        return newCoinsInBoxes;
      });
      setBoxToFill((boxToFill + 1) % nBoxes);
    } else {
      setMessage(level0WrongChest);
      setShowModal(true);
    }
  };

  const resetCoins = useCallback(() => {
    setDroppedCoins(new Set());
    setCoinsInBoxes(Array(nBoxes).fill([]));
  }, [nBoxes]);

  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCorrect = () => {
    if (levelProgress[LevelNumber] + 1 === REPEATS) {
      setMessage(level0Correct + nextLevelUnlocked);
    } else {
      setMessage(level0Correct);
    }
    setShowModal(true);
    increaseProgress(levelProgress, setLevelProgress, LevelNumber);
    resetCoins();
  };

  const handleIncorrect = () => {
    setMessage(level0Incorrect);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div style={{ margin: "1dvh" }}>
      <div
        className="Welcome-label"
        style={{ fontFamily: FONT, fontSize: "3.5dvh", whiteSpace: "pre-line" }}
      >
        {level0Welcome}
      </div>
      <ProgressPopup show={showModal} handleClose={hideModal}>
        {message}
      </ProgressPopup>
      <DndProvider options={HTML5toTouch}>
        <div style={{ marginTop: "1dvh" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50px",
            }}
          >
            {Array.from({ length: nCoins }).map(
              (_, idx) => !droppedCoins.has(idx) && <Coin key={idx} id={idx} />
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {Array.from({ length: nBoxes }).map((_, idx) => (
              <Box
                key={idx}
                id={idx}
                nBoxes={nBoxes}
                maxCoins={nCoins}
                onDropCoin={(item) => handleDropCoin(item, idx)}
                coins={coinsInBoxes[idx]}
              />
            ))}
          </div>
        </div>
      </DndProvider>
      {boxesFilled && (
        <div style={{ marginTop: "2dvh" }}>
          <span
            className="text-wrapper"
            style={{
              fontFamily: FONT,
              color: "white",
              fontSize: "3dvh",
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            }}
          >
            {level0Question}
          </span>
          <div style={{ height: "1dvh" }}></div>
          <InputBox
            handleCorrect={handleCorrect}
            handleIncorrect={handleIncorrect}
            dividend={nCoins}
            divisor={nBoxes}
            isLevel0={true}
          ></InputBox>
        </div>
      )}
    </div>
  );
}

export default Level0;
