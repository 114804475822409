import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "../App.css";
import ProgressPopup from "../components/ProgressPopup";
import { COLOR_LIGHT, FONT } from "../defaults";
import InputBox from "../components/InputBox";
import coinImage from "../images/coin1.svg";
import boxImage from "../images/chest.png";
import {
  level2Incorrect,
  Tutorial0Continue,
  Tutorial0Description6,
  Tutorial2Description1,
  Tutorial2Description2,
  Tutorial2Description3,
  Tutorial2Welcome,
} from "../strings";

function Coin() {
  return (
    <div
      style={{
        width: "5dvh",
        height: "5dvh",
      }}
    >
      <img
        src={coinImage}
        alt="coin"
        style={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
          zIndex: "2",
        }}
      />
    </div>
  );
}

function Tutorial2({ finishTutorial }) {
  const nBoxes = 3;
  const nCoins = 8;

  const [message, setMessage] = useState(Tutorial2Welcome);
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [isMoved, setIsMoved] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [tutorialProgress, setTutorialProgress] = useState(0);
  const [showInputBox, setShowInputBox] = useState(false);
  const [showContinue, setShowContine] = useState(false);

  const hideModal = () => {
    switch (tutorialProgress) {
      case 0:
        setShowModal(false);
        const fullDescription = Array.from(Tutorial2Description1);
        for (let i = 0; i <= fullDescription.length; i++) {
          setTimeout(() => {
            setDescription(fullDescription.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          startCoins();
        }, fullDescription.length * 50);
        break;
      case 3:
        finishTutorial();
        break;
      default:
        setShowModal(false);
    }
  };

  const startCoins = () => {
    setTimeout(() => {
      setIsMoved([true, false, false, false, false, false]);
    }, 1000);
    setTimeout(() => {
      setIsMoved([true, true, false, false, false, false]);
    }, 2000);
    setTimeout(() => {
      setIsMoved([true, true, true, false, false, false]);
    }, 3000);
    setTimeout(() => {
      setIsMoved([true, true, true, true, false, false]);
    }, 4000);
    setTimeout(() => {
      setIsMoved([true, true, true, true, true, false]);
    }, 5000);
    setTimeout(() => {
      setIsMoved([true, true, true, true, true, true]);
    }, 6000);
    setTimeout(() => {
      setShowContine(true);
      setTutorialProgress(1);
    }, 7000);
  };

  const handleClick = () => {
    switch (tutorialProgress) {
      case 1:
        setShowContine(false);
        let description1 = Array.from(Tutorial2Description2);
        for (let i = 0; i <= description1.length; i++) {
          setTimeout(() => {
            setDescription(description1.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(2);
          setShowContine(true);
        }, description1.length * 50);
        break;
      case 2:
        setShowContine(false);
        let description2 = Array.from(Tutorial2Description3);
        for (let i = 0; i <= description2.length; i++) {
          setTimeout(() => {
            setDescription(description2.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setShowInputBox(true);
        }, description2.length * 50);
        break;
      default:
    }
  };

  const handleCorrect = () => {
    setMessage(Tutorial0Description6);
    setTutorialProgress(3);
    setShowModal(true);
  };

  const handleIncorrect = () => {
    setMessage(level2Incorrect);
    setShowModal(true);
  };

  const boxSize = `calc(min(40vw / ${nBoxes}, 15vw))`;

  const [boxPositions, setBoxPositions] = useState([]);
  const boxRefs = useRef([]);
  const [coinPositions, setCoinPositions] = useState([]);
  const coinRefs = useRef([]);

  useEffect(() => {
    const positions = boxRefs.current.map((box) => {
      const rect = box.getBoundingClientRect();
      return { x: rect.left + rect.width / 8, y: rect.top + rect.height / 2 };
    });
    setBoxPositions(positions);

    const coinPos = coinRefs.current.map((coin, index) => {
      const rect = coin.getBoundingClientRect();
      const coinWidth = rect.width;
      const adjustedX =
        index === 3 || index === 4 || index === 5
          ? rect.left + window.innerHeight / 40 - coinWidth
          : rect.left + window.innerHeight / 40;
      return { x: adjustedX, y: rect.top + window.innerHeight / 40 };
    });
    setCoinPositions(coinPos);
  }, []);

  return (
    <div style={{ margin: "1dvh" }}>
      <div
        className="Welcome-label"
        style={{
          fontFamily: FONT,
          fontSize: "4dvh",
          minHeight: "9dvh",
          whiteSpace: "pre-wrap",
        }}
      >
        {description}
      </div>
      <ProgressPopup
        show={showModal}
        handleClose={hideModal}
        showTutorialSkip={tutorialProgress === 0}
        finishTutorial={finishTutorial}
      >
        {message}
      </ProgressPopup>
      <div style={{ marginTop: "1dvh" }}>
        <div
          style={{ display: "flex", justifyContent: "center", height: "10dvh" }}
        >
          {Array.from({ length: nCoins }).map((_, idx) => (
            <motion.div
              key={idx}
              ref={(el) => (coinRefs.current[idx] = el)}
              animate={{
                x:
                  isMoved[idx] &&
                  boxPositions[idx % nBoxes] &&
                  coinPositions[idx]
                    ? boxPositions[idx % nBoxes].x - coinPositions[idx].x
                    : 0,
                y:
                  isMoved[idx] && boxPositions[idx % nBoxes] && coinPositions[0]
                    ? boxPositions[idx % nBoxes].y - coinPositions[0].y
                    : 0,
              }}
              transition={{ duration: 1 }}
              style={{ zIndex: 1 }}
            >
              <Coin key={idx} id={idx} style={{ zIndex: 10 }} />
            </motion.div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {Array.from({ length: nBoxes }).map((_, idx) => (
            <div
              key={idx}
              ref={(el) => (boxRefs.current[idx] = el)}
              style={{
                width: boxSize,
                height: boxSize,
                backgroundColor: "none",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <img
                src={boxImage}
                alt="Box"
                style={{
                  position: "absolute",
                  width: boxSize,
                  height: boxSize,
                  zIndex: 0,
                  filter:
                    "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
                }}
              />
            </div>
          ))}
        </div>
      </div>
      {showInputBox && (
        <InputBox
          handleCorrect={handleCorrect}
          handleIncorrect={handleIncorrect}
          dividend={nCoins}
          divisor={nBoxes}
          remainder={2}
        ></InputBox>
      )}
      {showContinue && (
        <button
          onClick={handleClick}
          className="button"
          style={{
            padding: "1dvh",
            fontFamily: FONT,
            color: "white",
            fontSize: "3dvh",
            backgroundColor: COLOR_LIGHT,
            borderRadius: "0.25em",
            border: "none",
            boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            marginTop: "2dvh",
            cursor: "pointer",
          }}
        >
          {Tutorial0Continue}
        </button>
      )}
    </div>
  );
}

export default Tutorial2;
