import React, { useState } from "react";
import "../App.css";
import {
  COLOR_DARK,
  COLOR_DIVISOR,
  COLOR_LIGHT,
  COLOR_MEDIUM,
  FONT,
} from "../defaults";
import {
  InputBoxCheck,
  level5EqualResults,
  level5Numbers,
  Tutorial0Continue,
  Tutorial0Description6,
  Tutorial5Description1,
  Tutorial5Description2,
  Tutorial5Description3,
  Tutorial5Description4,
  Tutorial5Welcome,
} from "../strings";
import ProgressPopup from "../components/ProgressPopup";

function Tutorial5({ finishTutorial }) {
  const multiplicand = 3;
  const multiplier = 4;
  const [message, setMessage] = useState("");
  const [disableInput, setDisableInput] = useState(true);
  const [buttonText, setButtonText] = useState(InputBoxCheck);
  const [checkProgress, setCheckProgress] = useState(0);

  const inputMultiplier = 4;
  const [inputQuotient, setInputQuotient] = useState("");
  const [modalMessage, setModalMessage] = useState(Tutorial5Welcome);
  const [tutorialMessage, setTutorialMessage] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [tutorialProgress, setTutorialProgress] = useState(0);
  const [showContinue, setShowContinue] = useState(false);

  const hideModal = () => {
    setShowModal(false);
    continueTutorial();
  };

  const continueTutorial = () => {
    switch (tutorialProgress) {
      case 0:
        const description1 = Array.from(Tutorial5Description1);
        for (let i = 0; i <= description1.length; i++) {
          setTimeout(() => {
            setTutorialMessage(description1.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setShowContinue(true);
        }, description1.length * 50);
        setTutorialProgress(1);
        break;
      case 1:
        setShowContinue(false);
        const description2 = Array.from(Tutorial5Description2);
        for (let i = 0; i <= description2.length; i++) {
          setTimeout(() => {
            setTutorialMessage(description2.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setShowContinue(true);
        }, description2.length * 50);
        setTutorialProgress(2);
        break;
      case 2:
        setShowContinue(false);
        const description3 = Array.from(Tutorial5Description3);
        for (let i = 0; i <= description3.length; i++) {
          setTimeout(() => {
            setTutorialMessage(description3.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setShowContinue(true);
        }, description3.length * 50);
        setTutorialProgress(3);
        break;
      case 3:
        setShowContinue(false);
        const description4 = Array.from(Tutorial5Description4);
        for (let i = 0; i <= description4.length; i++) {
          setTimeout(() => {
            setTutorialMessage(description4.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setDisableInput(false);
        }, description4.length * 50);
        setTutorialProgress(4);
        break;
      default:
        finishTutorial();
    }
  };

  const checkAnswers = () => {
    const isQuotientCorrect = parseInt(inputQuotient) === multiplier;
    if (checkProgress === 0) {
      if (isQuotientCorrect) {
        setModalMessage(Tutorial0Description6);
        setShowModal(true);
      } else if (inputQuotient === "") {
        setMessage(level5Numbers);
      } else {
        setMessage(level5EqualResults);
        setInputQuotient("");
      }
      setDisableInput(true);
      setCheckProgress(1);
      setButtonText(Tutorial0Continue);
    } else {
      setMessage("");
      setDisableInput(false);
      setInputQuotient("");
      setCheckProgress(0);
      setButtonText(InputBoxCheck);
    }
  };

  const inputStyle = {
    color: "white",
    fontSize: "3dvh",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    backgroundColor: COLOR_MEDIUM,
    borderRadius: "0.25em",
    boxShadow: "inset 0.3svh 0.3svh 0.6svh rgba(0, 0.5, 0, 0.5)",
    border: "none",
    textAlign: "center",
    width: "5dvh",
    fontFamily: FONT,
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    fontFamily: FONT,
    padding: "3dvh",
    borderRadius: "0.25em",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    maxWidth: "300px",
    margin: "2dvh auto",
    backgroundColor: COLOR_DARK,
  };

  const rowStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    fontSize: "3dvh",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "3dvh",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    border: "none",
    cursor: "pointer",
    backgroundColor: COLOR_LIGHT,
    borderRadius: "0.25em",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    color: "white",
  };

  const messageStyle = {
    marginTop: "10px",
    fontSize: "3dvh",
    fontWeight: "bold",
  };

  return (
    <div>
      <style>
        {`
          /* Hide arrows in input of type number for WebKit browsers (Chrome, Safari) This was generated by a LLM.*/
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }

          /* Hide arrows in input of type number for Firefox */
          input[type="number"] {
              -moz-appearance: textfield;
          }
        `}
      </style>
      <div
        className="Welcome-label"
        style={{
          fontFamily: FONT,
          fontSize: "3.5dvh",
          minHeight: "9dvh",
          whiteSpace: "pre-wrap",
        }}
      >
        {tutorialMessage}
      </div>
      <ProgressPopup
        show={showModal}
        handleClose={hideModal}
        showTutorialSkip={tutorialProgress === 0}
        finishTutorial={finishTutorial}
      >
        {modalMessage}
      </ProgressPopup>
      <div style={containerStyle}>
        <div style={rowStyle}>
          <span style={{ color: COLOR_DIVISOR }}>{multiplicand}</span>
          &nbsp;x&nbsp;
          <input
            type="number"
            disabled={true}
            value={inputMultiplier}
            style={inputStyle}
          />
          &nbsp;= {multiplicand * multiplier}
        </div>
        <div style={rowStyle}>
          {multiplicand * multiplier} :&nbsp;
          <span style={{ color: COLOR_DIVISOR }}>{multiplicand}</span>
          &nbsp;=&nbsp;
          <input
            type="number"
            disabled={disableInput}
            value={inputQuotient}
            onChange={(e) => setInputQuotient(e.target.value)}
            style={inputStyle}
          />
        </div>
        {!disableInput || checkProgress ? (
          <button style={buttonStyle} onClick={checkAnswers}>
            {buttonText}
          </button>
        ) : null}
        {message && <div style={messageStyle}>{message}</div>}
      </div>
      {showContinue && (
        <button
          onClick={continueTutorial}
          className="button"
          style={{
            padding: "1dvh",
            fontFamily: FONT,
            color: "white",
            fontSize: "3dvh",
            backgroundColor: COLOR_LIGHT,
            borderRadius: "0.25em",
            border: "none",
            boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            marginTop: "1dvh",
            cursor: "pointer",
          }}
        >
          {Tutorial0Continue}
        </button>
      )}
    </div>
  );
}

export default Tutorial5;
