import React from "react";
import { useDrop } from "react-dnd";
import coin100 from "../images/coin100.svg";
import coin10 from "../images/coin10.svg";
import coin1 from "../images/coin1.svg";
import { COLOR_LIGHT, FONT } from "../defaults";

const coinImages = {
  0: coin100,
  1: coin10,
  2: coin1,
};

/* 
This component implements a box into which coins can be dropped.
The coins can be displayed either grouped or not grouped.
Parts of it were created with the help of LLMs.
*/
function Box({ id, coins, onDropCoin, groupCoins = false }) {
  const [, drop] = useDrop({
    accept: "coin",
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        onDropCoin(item.id, id);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const coinStyle = {
    width: "4dvh",
    height: "4dvh",
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
  };

  const groupedCoinStyle = {
    width: "10dvh",
    height: "4dvh",
    display: "flex",
    justifyContent: "center",
    fontStyle: FONT,
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  };

  const boxStyle = {
    display: "flex",
    flexWrap: "wrap",
    position: "absolute",
    width: "30dvh",
    height: "9dvh",
    zIndex: 0,
    padding: "0.5dvh",
    filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
    borderRadius: "0.25em",
    alignItems: "center",
    backgroundColor: COLOR_LIGHT,
  };

  const dropStyle = {
    width: "30dvh",
    height: "11dvh",
    backgroundColor: "none",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
  };

  return (
    <div ref={drop} style={dropStyle}>
      <div alt="Box" style={boxStyle}>
        {Array.from({ length: 3 }, (_, id) => (
          <React.Fragment key={id}>
            {groupCoins ? (
              <div key={0} style={groupedCoinStyle}>
                {coins[id]} x&nbsp;
                <img src={coinImages[id]} alt="coin" />
              </div>
            ) : (
              Array.from({ length: coins[id] }).map((_, coinId) => (
                <div key={coinId} style={coinStyle}>
                  <img src={coinImages[id]} alt="coin" />
                </div>
              ))
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Box;
