import React, { useState } from "react";
import RopePiece from "../components/RopePiece";
import Scissors from "../components/Scissors";
import InputBox from "../components/InputBox";
import ProgressPopup from "../components/ProgressPopup";
import { COLOR_LIGHT, FONT } from "../defaults";
import {
  level3Incorrect1,
  level3Incorrect3,
  level4Incorrect,
  level4Question,
  Tutorial0Continue,
  Tutorial0Description6,
  Tutorial3Description2,
  Tutorial4Description1,
  Tutorial4Description2,
  Tutorial4Welcome,
} from "../strings";

function Tutorial4({ finishTutorial }) {
  const [description, setDescription] = useState("");
  const [scissorProgress, setScissorProgress] = useState(0);
  const pieceCount = 3;
  const pieceSize = 7;
  const remainder = 3;
  const [fallingPieces, setFallingPieces] = useState(
    Array(pieceCount).fill(false)
  );
  const [tutorialProgress, setTutorialProgress] = useState(0);
  const [showInputbox, setShowInputbox] = useState(false);
  const [showContinue, setShowContinue] = useState(false);

  const getFallHeight = (index) => {
    return index % 2 === 0 ? 16 : 13;
  };

  const numberLineValues = Array.from(
    { length: remainder + pieceCount * pieceSize + 1 },
    (_, index) => index
  );

  const [message, setMessage] = useState(Tutorial4Welcome);
  const [showModal, setShowModal] = useState(true);

  const handleCorrect = () => {
    setMessage(Tutorial0Description6);
    setShowModal(true);
    setTutorialProgress(4);
  };

  const handleIncorrect = () => {
    setMessage(
      level3Incorrect1 +
        "\n" +
        (pieceCount * pieceSize + remainder) +
        ":" +
        pieceSize +
        level3Incorrect3 +
        pieceSize +
        "." +
        level4Incorrect
    );
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
    switch (tutorialProgress) {
      case 0:
        const fullDescription = Array.from(Tutorial4Description1);
        for (let i = 0; i <= fullDescription.length; i++) {
          setTimeout(() => {
            setDescription(fullDescription.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          startScissors();
        }, fullDescription.length * 50);
        setTutorialProgress(1);
        break;
      case 4:
        finishTutorial();
        break;
      default:
    }
  };

  const startScissors = () => {
    setTimeout(() => {
      setScissorProgress(1);
      setFallingPieces([true, false, false]);
    }, 1000);
    setTimeout(() => {
      setScissorProgress(2);
      setFallingPieces([true, true, false]);
    }, 2000);
    setTimeout(() => {
      setScissorProgress(3);
      setFallingPieces([true, true, true]);
      setTutorialProgress(2);
      setShowContinue(true);
    }, 3000);
  };

  const startAnswer = () => {
    if (tutorialProgress === 2) {
      setShowContinue(false);
      const secondDescription = Array.from(Tutorial4Description2);
      for (let i = 0; i <= secondDescription.length; i++) {
        setTimeout(() => {
          setDescription(secondDescription.slice(0, i));
        }, i * 50);
      }
      setTimeout(() => {
        setTutorialProgress(3);
        setShowContinue(true);
      }, secondDescription.length * 50);
    } else {
      setShowContinue(false);
      const fullDescription = Array.from(Tutorial3Description2);
      for (let i = 0; i <= fullDescription.length; i++) {
        setTimeout(() => {
          setDescription(fullDescription.slice(0, i));
        }, i * 50);
      }
      setTimeout(() => {
        setShowInputbox(true);
      }, fullDescription.length * 50);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        marginTop: "2dvh",
        marginBottom: "1dvh",
        whiteSpace: "pre-wrap",
      }}
    >
      <div
        className="Welcome-label"
        style={{ fontFamily: FONT, fontSize: "3.5dvh", minHeight: "9dvh" }}
      >
        {description}
      </div>
      <ProgressPopup
        show={showModal}
        handleClose={hideModal}
        showTutorialSkip={tutorialProgress === 0}
        finishTutorial={finishTutorial}
      >
        {message}
      </ProgressPopup>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "4dvh",
          marginBottom: "1dvh",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
            alignItems: "start",
            height: "5dvh",
          }}
        >
          {Array.from({ length: pieceCount }).map((_, index) => (
            <React.Fragment key={index}>
              <RopePiece
                pieceSize={pieceSize}
                isFalling={fallingPieces[index]}
                fallHeight={fallingPieces[index] ? getFallHeight(index) : 0}
                showDot={index === scissorProgress}
              />
              {index < pieceCount && (
                <div style={{ position: "relative", width: `0px` }}>
                  <Scissors
                    onClick={() => _}
                    visibility={scissorProgress === index ? 1 : 0}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
          <RopePiece
            pieceSize={remainder}
            isFalling={false}
            fallHeight={0}
            showDot={scissorProgress === pieceCount}
          />
        </div>
        <div
          className="number-line"
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: `${(remainder + pieceCount * pieceSize) * 5 + 5}dvh`,
            height: "6dvh",
            marginTop: "1dvh",
          }}
        >
          <hr
            className="line"
            style={{
              position: "absolute",
              top: "50%",
              width: `${(remainder + pieceCount * pieceSize) * 5}dvh`,
              height: "0.3dvh",
              backgroundColor: "black",
              border: "0.15dvh solid black",
            }}
          ></hr>
          <div style={{ display: "flex" }}>
            {numberLineValues.map((value, index) => (
              <div
                key={index}
                className="tick"
                style={{
                  position: "relative",
                  height: "100%",
                  width: "5dvh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="number"
                  style={{
                    fontFamily: FONT,
                    fontSize: "3dvh",
                    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {value}
                </div>
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "2px",
                    height: "15px",
                    backgroundColor: "black",
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showContinue && (
        <button
          onClick={startAnswer}
          className="button"
          style={{
            padding: "1dvh",
            fontFamily: FONT,
            color: "white",
            fontSize: "3dvh",
            backgroundColor: COLOR_LIGHT,
            borderRadius: "0.25em",
            border: "none",
            boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            marginTop: "10dvh",
            cursor: "pointer",
          }}
        >
          {Tutorial0Continue}
        </button>
      )}
      {showInputbox && (
        <div style={{ marginTop: "10dvh" }}>
          <span
            className="text-wrapper"
            style={{
              fontFamily: FONT,
              color: "white",
              fontSize: "3dvh",
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            }}
          >
            {level4Question}
          </span>
          <div style={{ height: "1dvh" }}></div>
          <InputBox
            handleCorrect={handleCorrect}
            handleIncorrect={handleIncorrect}
            dividend={pieceCount * pieceSize + remainder}
            divisor={pieceSize}
            remainder={remainder}
          >
            {" "}
          </InputBox>
        </div>
      )}
    </div>
  );
}

export default Tutorial4;
