import React, { useEffect, useState } from "react";
import "../App.css";
import ProgressPopup from "../components/ProgressPopup";
import { COLOR_LIGHT, FONT, increaseProgress, REPEATS } from "../defaults";
import {
  level0Correct,
  Level1False,
  level1IncorrectNo,
  level1IncorrectYes,
  level1Question,
  Level1True,
  level1Welcome,
  nextLevelUnlocked,
} from "../strings";
import coinImage from "../images/coin1.svg";
import boxImage from "../images/chest.png";

const LevelNumber = 1;

function calculateInitialBoxes(currentProgress) {
  const numbers = [3, 2, 4];
  if (currentProgress >= numbers.length) {
    return Math.ceil(Math.random() * 4) + 1;
  }
  return numbers[currentProgress];
}

function calculateInitialCoins(currentProgress) {
  const numbers = [2, 3, 4];
  if (currentProgress >= numbers.length) {
    return Math.ceil(Math.random() * 4) + 1;
  }
  return numbers[currentProgress];
}

function Coin() {
  return (
    <div
      style={{
        width: "5dvh",
        height: "5dvh",
      }}
    >
      <img
        src={coinImage}
        alt="coin"
        style={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
        }}
      />
    </div>
  );
}

function Level1({ levelProgress, setLevelProgress }) {
  const [nBoxes, setNBoxes] = useState(
    calculateInitialBoxes(levelProgress[LevelNumber])
  );
  const [nCoins, setNCoins] = useState(
    calculateInitialCoins(levelProgress[LevelNumber])
  );

  useEffect(() => {
    setNBoxes(calculateInitialBoxes(levelProgress[LevelNumber]));
    setNCoins(calculateInitialCoins(levelProgress[LevelNumber]));
  }, [levelProgress]);

  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCorrect = () => {
    if (levelProgress[LevelNumber] + 1 === REPEATS) {
      setMessage(level0Correct + nextLevelUnlocked);
    } else {
      setMessage(level0Correct);
    }
    setShowModal(true);
    increaseProgress(levelProgress, setLevelProgress, LevelNumber);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const boxSize = `calc(min(40vw / ${nBoxes}, 15vw))`;

  const handleTrue = () => {
    if (nCoins >= nBoxes) {
      handleCorrect();
    } else {
      setMessage(level1IncorrectNo);
      setShowModal(true);
    }
  };

  const handleFalse = () => {
    if (nCoins < nBoxes) {
      handleCorrect();
    } else {
      setMessage(level1IncorrectYes);
      setShowModal(true);
    }
  };

  const buttonStyle = {
    padding: "1dvh",
    fontFamily: FONT,
    color: "white",
    fontSize: "3dvh",
    backgroundColor: COLOR_LIGHT,
    borderRadius: "0.25em",
    border: "none",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    marginLeft: "2dvh",
    marginRight: "2dvh",
    marginTop: "1dvh",
    width: "10dvh",
  };

  return (
    <div style={{ margin: "1dvh" }}>
      <div
        className="Welcome-label"
        style={{ fontFamily: FONT, fontSize: "3.5dvh", whiteSpace: "pre-line" }}
      >
        {level1Welcome}
      </div>
      <ProgressPopup show={showModal} handleClose={hideModal}>
        {message}
      </ProgressPopup>
      <div style={{ marginTop: "1dvh" }}>
        <div
          style={{ display: "flex", justifyContent: "center", height: "7dvh" }}
        >
          {Array.from({ length: nCoins }).map((_, idx) => (
            <Coin key={idx} id={idx} />
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {Array.from({ length: nBoxes }).map((_, idx) => (
            <div
              style={{
                width: boxSize,
                height: boxSize,
                backgroundColor: "none",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <img
                src={boxImage}
                alt="Box"
                style={{
                  position: "absolute",
                  width: boxSize,
                  height: boxSize,
                  zIndex: 0,
                  filter:
                    "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div style={{ marginTop: "2dvh" }}>
        <span
          className="text-wrapper"
          style={{
            fontFamily: FONT,
            color: "white",
            fontSize: "3dvh",
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
          }}
        >
          {level1Question}
        </span>
        <div style={{ marginTop: "1dvh" }}>
          <button onClick={handleTrue} className="button" style={buttonStyle}>
            {Level1True}
          </button>
          <button onClick={handleFalse} className="button" style={buttonStyle}>
            {Level1False}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Level1;
