import React, { useState, useEffect } from "react";
import coin1000 from "../images/newcoin1000.svg";
import coin100 from "../images/newcoin100.svg";
import coin10 from "../images/newcoin10.svg";
import coin1 from "../images/newcoin1.svg";
import ProgressPopup from "../components/ProgressPopup";
import {
  COLOR_DIVISOR,
  COLOR_HIGHLIGHTED,
  COLOR_LIGHT,
  COLOR_MEDIUM,
  FONT,
} from "../defaults";
import {
  InputBoxCheck,
  Tutorial0Continue,
  Tutorial0Description6,
  Tutorial11Description1,
  Tutorial11Description2,
  Tutorial11Description3,
  Tutorial11Description4,
  Tutorial11Description5,
  Tutorial11Description6,
  Tutorial11Description7,
  Tutorial11Description8,
  Tutorial11Welcome,
} from "../strings";

const coinImages = {
  0: coin1000,
  1: coin100,
  2: coin10,
  3: coin1,
};

function Coin({ coinImage }) {
  return (
    <div
      style={{
        width: "5dvh",
        height: "5dvh",
      }}
    >
      <img
        src={coinImage}
        alt="coin"
        style={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
        }}
      />
    </div>
  );
}

function Tutorial11({ finishTutorial }) {
  const dividend = 7651;
  const divisor = 3;
  const [lineCount, setLineCount] = useState(1);
  const [inputsLeft, setInputsLeft] = useState(
    Array.from({ length: 7 }, () => "")
  );
  const [inputsRight, setInputsRight] = useState(
    Array.from({ length: 4 }, () => "")
  );
  const [solutionLeft, setSolutionLeft] = useState([]);
  const [solutionRight, setSolutionRight] = useState([]);
  const [highlightedInput, setHighlightedInput] = useState(new Set());
  const [tutorialProgress, setTutorialProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [modalMessage, setModalMessage] = useState(Tutorial11Welcome);
  const [showModal, setShowModal] = useState(true);
  const [showContine, setShowContinue] = useState(false);
  const [disableInput, setDisableInput] = useState(true);

  const startText = () => {
    switch (tutorialProgress) {
      case 0:
        const description1 = Array.from(Tutorial11Description1);
        for (let i = 0; i <= description1.length; i++) {
          setTimeout(() => {
            setMessage(description1.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setShowContinue(true);
        }, description1.length * 50);
        setTutorialProgress(1);
        break;
      case 1:
        setShowContinue(false);
        const description2 = Array.from(Tutorial11Description2);
        for (let i = 0; i <= description2.length; i++) {
          setTimeout(() => {
            setMessage(description2.slice(0, i).join(""));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(2);
          setDisableInput(false);
          setShowContinue(true);
        }, description2.length * 50);
        break;
      case 2:
        setShowContinue(false);
        setDisableInput(true);
        const description3 = Array.from(Tutorial11Description3);
        for (let i = 0; i <= description3.length; i++) {
          setTimeout(() => {
            setMessage(description3.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(3);
          setDisableInput(false);
          setShowContinue(true);
        }, description3.length * 50);
        break;
      case 3:
        setShowContinue(false);
        setDisableInput(true);
        const description4 = Array.from(Tutorial11Description4);
        for (let i = 0; i <= description4.length; i++) {
          setTimeout(() => {
            setMessage(description4.slice(0, i).join(""));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(4);
          setDisableInput(false);
          setShowContinue(true);
        }, description4.length * 50);
        break;
      case 4:
        setShowContinue(false);
        setDisableInput(true);
        const description5 = Array.from(Tutorial11Description5);
        for (let i = 0; i <= description5.length; i++) {
          setTimeout(() => {
            setMessage(description5.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(5);
          setDisableInput(false);
          setShowContinue(true);
        }, description5.length * 50);
        break;
      case 5:
        setShowContinue(false);
        setDisableInput(true);
        const description6 = Array.from(Tutorial11Description6);
        for (let i = 0; i <= description6.length; i++) {
          setTimeout(() => {
            setMessage(description6.slice(0, i).join(""));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(6);
          setDisableInput(false);
          setShowContinue(true);
        }, description6.length * 50);
        break;
      case 6:
        setShowContinue(false);
        setDisableInput(true);
        const description7 = Array.from(Tutorial11Description7);
        for (let i = 0; i <= description7.length; i++) {
          setTimeout(() => {
            setMessage(description7.slice(0, i));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(7);
          setDisableInput(false);
          setShowContinue(true);
        }, description7.length * 50);
        break;
      case 7:
        setShowContinue(false);
        setDisableInput(true);
        const description8 = Array.from(Tutorial11Description8);
        for (let i = 0; i <= description8.length; i++) {
          setTimeout(() => {
            setMessage(description8.slice(0, i).join(""));
          }, i * 50);
        }
        setTimeout(() => {
          setTutorialProgress(8);
          setDisableInput(false);
          setShowContinue(true);
        }, description8.length * 50);
        break;
      default:
    }
  };

  useEffect(() => {
    const rem = Array.from(dividend.toString(), Number);
    const sol = [0, 0, 0, 0];
    const div = divisor;
    let calcLeft = [];
    let calcRight = [];
    for (let i = 0; i < 4; i++) {
      if (rem[i] >= div) {
        sol[i] = parseInt(rem[i] / div);
        rem[i] = rem[i] % div;
        calcLeft = [...calcLeft, rem[i]];
      } else {
        calcLeft = [...calcLeft, rem[i]];
      }
      if (i < 3) {
        rem[i + 1] += rem[i] * 10;
        rem[i] = 0;
        calcLeft = [...calcLeft, rem[i + 1]];
      }
      calcRight = [...calcRight, sol[i]];
    }
    setSolutionLeft(calcLeft);
    setSolutionRight(calcRight);
    console.log(calcLeft);
  }, [dividend, divisor]);

  const hideModal = () => {
    setShowModal(false);
    if (tutorialProgress === 8) {
      finishTutorial();
    } else {
      startText();
    }
  };

  const handleChangeLeft = (id) => (e) => {
    const newInputs = [...inputsLeft];
    newInputs[id] = e.target.value;
    setInputsLeft(newInputs);
  };

  const handleChangeRight = (id) => (e) => {
    const newInputs = [...inputsRight];
    newInputs[id] = e.target.value;
    setInputsRight(newInputs);
  };

  const checkLine = () => {
    var newHighlighted = new Set();
    if (
      parseInt(inputsLeft[lineCount - 1]) !==
      parseInt(solutionLeft[lineCount - 1])
    ) {
      newHighlighted.add(0);
      inputsLeft[lineCount - 1] = "";
    }
    if (
      parseInt(inputsRight[Math.floor((lineCount - 1) / 2)]) !==
      parseInt(solutionRight[Math.floor((lineCount - 1) / 2)])
    ) {
      newHighlighted.add(1);
      inputsRight[Math.floor((lineCount - 1) / 2)] = "";
    }
    if (newHighlighted.size === 0) {
      if (solutionLeft.length > lineCount) {
        setLineCount(lineCount + 1);
        startText();
      } else {
        setModalMessage(Tutorial0Description6);
        setShowModal(true);
      }
    } else {
      setHighlightedInput(newHighlighted);
      setTimeout(() => {
        setHighlightedInput(new Set());
      }, 2000);
    }
  };

  const inputStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: FONT,
    color: "white",
    fontSize: "3dvh",
    border: "none",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    width: "5dvh",
    borderRadius: "0.25em",
  };

  const buttonStyle = {
    marginLeft: "2dvh",
    width: "12dvh",
    padding: "1dvh",
    fontFamily: FONT,
    color: "white",
    fontSize: "3dvh",
    backgroundColor: COLOR_LIGHT,
    borderRadius: "0.25em",
    border: "none",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  const getBorderStyle = () => {
    let borderTop = "0.25dvh solid black";
    let borderBottom = "0.25dvh solid black";
    let borderLeft = "0.25dvh solid black";
    let borderRight = "0.25dvh solid black";

    return {
      borderTop,
      borderBottom,
      borderLeft,
      borderRight,
    };
  };

  return (
    <div>
      <div
        className="Welcome-label"
        style={{
          fontFamily: FONT,
          fontSize: "3.5dvh",
          minHeight: "5dvh",
          whiteSpace: "pre-wrap",
        }}
      >
        {message}
      </div>
      <ProgressPopup
        show={showModal}
        handleClose={hideModal}
        showTutorialSkip={tutorialProgress === 0}
        finishTutorial={finishTutorial}
      >
        {modalMessage}
      </ProgressPopup>
      <div
        className="container"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <style>
          {`
              /* Hide arrows in input of type number for WebKit browsers (Chrome, Safari) This was generated by a LLM.*/
              input[type="number"]::-webkit-inner-spin-button,
              input[type="number"]::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
              }

              /* Hide arrows in input of type number for Firefox */
              input[type="number"] {
                  -moz-appearance: textfield;
              }
            `}
        </style>
        <div
          className="left-side"
          style={{
            display: "flex",
            height: `${(lineCount + 2) * 7}dvh`,
            fontFamily: FONT,
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            width: "45%",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                height: "7dvh",
                justifyContent: "flex-end",
              }}
            >
              {Array.from({ length: 4 }).map((_, id) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {dividend.toString()[id]} x&nbsp;
                  <Coin key={[id, 0]} id={[id, 0]} coinImage={coinImages[id]} />
                  &nbsp;
                </div>
              ))}
            </div>
            {Array.from({ length: lineCount }).map((_, id) => (
              <div
                style={{
                  display: "flex",
                  height: "7dvh",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    title="Input"
                    type="number"
                    value={inputsLeft[id]}
                    onChange={handleChangeLeft(id)}
                    style={{
                      ...inputStyle,
                      ...getBorderStyle(),
                      backgroundColor:
                        highlightedInput.has(0) && id + 1 === lineCount
                          ? COLOR_HIGHLIGHTED
                          : COLOR_MEDIUM,
                    }}
                    placeholder=""
                    disabled={id + 1 !== lineCount || disableInput}
                  ></input>
                  &nbsp;x&nbsp;
                  <Coin
                    key={[id, 0]}
                    id={[id, 0]}
                    coinImage={coinImages[Math.floor((id + 1) / 2)]}
                  />
                  &nbsp;
                </div>
                {Array.from(
                  dividend.toString().substring(Math.floor((id + 3) / 2))
                ).map((value, id2) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {value} x&nbsp;
                    <Coin
                      key={[Math.floor((id + 1) / 2) + id2 + 1, 0]}
                      id={[Math.floor((id + 1) / 2) + id2 + 1, 0]}
                      coinImage={coinImages[Math.floor((id + 1) / 2) + id2 + 1]}
                    />
                    &nbsp;
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              height: "7dvh",
              alignItems: "center",
              fontFamily: FONT,
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            }}
          >
            <div>
              &nbsp; : <span style={{ color: COLOR_DIVISOR }}>{divisor}</span>
            </div>
          </div>
        </div>
        <div style={{ width: "10%" }}></div>
        <div
          className="right-side"
          style={{
            display: "flex",
            fontFamily: FONT,
            textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            width: "45%",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              fontFamily: FONT,
              height: "7dvh",
              alignItems: "center",
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            }}
          >
            <div>
              <span style={{ color: COLOR_DIVISOR }}>{divisor}</span> x &nbsp;
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {Array.from({ length: lineCount + 1 }).map((_, id) =>
              id % 2 === 1 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "7dvh",
                    alignItems: "center",
                  }}
                >
                  {solutionRight
                    .slice(0, parseInt((id + 1) / 2))
                    .map((value, id2) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {id2 === parseInt(id / 2) ? (
                          <input
                            title="Input"
                            type="number"
                            value={inputsRight[id2]}
                            onChange={handleChangeRight(parseInt(id / 2))}
                            style={{
                              ...inputStyle,
                              ...getBorderStyle(),
                              backgroundColor:
                                highlightedInput.has(1) && id === lineCount
                                  ? COLOR_HIGHLIGHTED
                                  : COLOR_MEDIUM,
                            }}
                            placeholder=""
                            disabled={
                              parseInt(id / 2) !== Math.floor(lineCount / 2) ||
                              disableInput
                            }
                          ></input>
                        ) : (
                          <div>{solutionRight[id2]}</div>
                        )}
                        &nbsp;x&nbsp;
                        <Coin
                          key={[id, id2]}
                          id={[id, id2]}
                          coinImage={coinImages[id2]}
                        />
                        &nbsp;
                      </div>
                    ))}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "7dvh",
                    alignItems: "center",
                  }}
                >
                  {solutionRight
                    .slice(0, parseInt((id + 1) / 2))
                    .map((value, id2) => (
                      <div style={{ display: "flex" }}>
                        {value}
                        &nbsp;x&nbsp;
                        <Coin
                          key={[id, id2]}
                          id={[id, id2]}
                          coinImage={coinImages[id2]}
                        />
                        &nbsp;
                      </div>
                    ))}
                </div>
              )
            )}
            {showContine && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  fontFamily: FONT,
                }}
              >
                <button
                  onClick={disableInput ? startText : checkLine}
                  style={buttonStyle}
                >
                  {disableInput ? Tutorial0Continue : InputBoxCheck}{" "}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tutorial11;
