import React, { useState, useEffect } from "react";
import "../App.css";
import {
  COLOR_DARK,
  COLOR_DIVISOR,
  COLOR_HIGHLIGHTED,
  COLOR_LIGHT,
  COLOR_MEDIUM,
  FONT,
  increaseProgress,
  REPEATS,
} from "../defaults";
import {
  InputBoxCheck,
  InputBoxRemainder,
  level0Correct,
  level2Welcome1,
  level2Welcome2,
  level3Incorrect1,
  level5EqualResults,
  level5Numbers,
  level5Welcome,
  level6EqualRemainders,
  level6RemainderSmaller1,
  level6RemainderSmaller2,
  level6RemainderSmaller3,
  nextLevelUnlocked,
  Tutorial0Continue,
} from "../strings";

const LevelNumber = 6;

function calculateMultiplicands(currentProgress) {
  const numbers = [3, 6, 1];
  if (currentProgress >= numbers.length) {
    return Math.ceil(Math.random() * 8) + 1;
  }
  return numbers[currentProgress];
}

function calculateMultipliers(currentProgress) {
  const numbers = [4, 2, 7];
  if (currentProgress >= numbers.length) {
    return Math.ceil(Math.random() * 8) + 1;
  }
  return numbers[currentProgress];
}

function calculateRemainders(multiplicand, currentProgress) {
  const numbers = [2, 1, 0];
  if (currentProgress >= numbers.length) {
    return Math.ceil(Math.random() * (multiplicand - 1));
  }
  return numbers[currentProgress];
}

function Level6({ levelProgress, setLevelProgress }) {
  const [multiplicand, setMultiplicand] = useState(
    calculateMultiplicands(levelProgress[LevelNumber])
  );
  const [multiplier, setMultiplier] = useState(
    calculateMultipliers(levelProgress[LevelNumber])
  );
  const [remainder, setRemainder] = useState(
    calculateRemainders(multiplicand, levelProgress[LevelNumber])
  );
  const [message, setMessage] = useState("");
  const [disableInput, setDisableInput] = useState(false);
  const [buttonText, setButtonText] = useState(InputBoxCheck);
  const [checkProgress, setCheckProgress] = useState(0);

  useEffect(() => {
    const mult = calculateMultiplicands(levelProgress[LevelNumber]);
    setMultiplicand(mult);
    setMultiplier(calculateMultipliers(levelProgress[LevelNumber]));
    setRemainder(calculateRemainders(mult, levelProgress[LevelNumber]));
    setInputMultiplier("");
    setInputQuotient("");
    setInputRemainder1("");
    setInputRemainder2("");
  }, [levelProgress]);

  const [inputMultiplier, setInputMultiplier] = useState("");
  const [inputQuotient, setInputQuotient] = useState("");
  const [inputRemainder1, setInputRemainder1] = useState("");
  const [inputRemainder2, setInputRemainder2] = useState("");
  const [highlightedInput, setHighlightedInput] = useState(0);

  const checkAnswers = () => {
    const isMultiplierCorrect = parseInt(inputMultiplier) === multiplier;
    const isQuotientCorrect = parseInt(inputQuotient) === multiplier;
    const isRemainder1Correct = parseInt(inputRemainder1) === remainder;
    const isRemainder2Correct = parseInt(inputRemainder2) === remainder;
    if (checkProgress === 0) {
      if (
        isMultiplierCorrect &&
        isQuotientCorrect &&
        isRemainder1Correct &&
        isRemainder2Correct
      ) {
        if (levelProgress[LevelNumber] + 1 === REPEATS) {
          setMessage(level0Correct + nextLevelUnlocked);
        } else {
          setMessage(level0Correct);
        }
      } else if (
        inputMultiplier === "" ||
        inputQuotient === "" ||
        inputRemainder1 === "" ||
        inputRemainder2 === ""
      ) {
        setMessage(level5Numbers);
      } else if (inputMultiplier !== inputQuotient) {
        setMessage(level5EqualResults);
        setHighlightedInput(1);
      } else if (inputRemainder1 !== inputRemainder2) {
        setMessage(level6EqualRemainders);
        setHighlightedInput(2);
      } else if (parseInt(inputRemainder1) >= multiplicand) {
        setMessage(
          <div>
            {level6RemainderSmaller1}
            <span style={{ color: COLOR_DIVISOR }}>
              {" "}
              {level6RemainderSmaller2}{" "}
            </span>{" "}
            {level6RemainderSmaller3}{" "}
          </div>
        );
        setHighlightedInput(2);
        setInputRemainder1("");
        setInputRemainder2("");
      } else if (isRemainder1Correct) {
        setMessage(level3Incorrect1);
        setHighlightedInput(1);
        setInputMultiplier("");
        setInputQuotient("");
      } else if (isMultiplierCorrect) {
        setMessage(level3Incorrect1);
        setHighlightedInput(2);
        setInputRemainder1("");
        setInputRemainder2("");
      } else {
        setMessage(level3Incorrect1);
        setInputMultiplier("");
        setInputQuotient("");
        setInputRemainder1("");
        setInputRemainder2("");
      }
      setDisableInput(true);
      setCheckProgress(1);
      setButtonText(Tutorial0Continue);
    } else {
      if (
        isMultiplierCorrect &&
        isQuotientCorrect &&
        isRemainder1Correct &&
        isRemainder2Correct
      ) {
        increaseProgress(levelProgress, setLevelProgress, LevelNumber);
        setInputMultiplier("");
        setInputQuotient("");
        setInputRemainder1("");
        setInputRemainder2("");
      }
      setDisableInput(false);
      setHighlightedInput(0);
      setMessage("");
      setCheckProgress(0);
      setButtonText(InputBoxCheck);
    }
  };

  const inputStyle = {
    color: "white",
    fontSize: "3dvh",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    backgroundColor: COLOR_MEDIUM,
    borderRadius: "0.25em",
    boxShadow: "inset 0.3svh 0.3svh 0.6svh rgba(0, 0.5, 0, 0.5)",
    border: "none",
    textAlign: "center",
    width: "5dvh",
    appearance: "textfield",
    MozAppearance: "textfield",
    WebkitAppearance: "none",
    fontFamily: FONT,
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    fontFamily: FONT,
    padding: "3dvh",
    borderRadius: "0.25em",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    maxWidth: "400px",
    margin: "2dvh auto",
    backgroundColor: COLOR_DARK,
  };

  const rowStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    fontSize: "3dvh",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "3dvh",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    border: "none",
    cursor: "pointer",
    backgroundColor: COLOR_LIGHT,
    borderRadius: "0.25em",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    color: "white",
  };

  const messageStyle = {
    marginTop: "10px",
    fontSize: "3dvh",
    fontWeight: "bold",
  };

  return (
    <div>
      <div
        className="Welcome-label"
        style={{ fontFamily: FONT, fontSize: "3.5dvh" }}
      >
        {level2Welcome1}
        {multiplicand * multiplier + remainder}
        {level2Welcome2}
        <span style={{ color: COLOR_DIVISOR }}>{multiplicand}</span>?<br></br>
        {level5Welcome}
      </div>
      <div style={containerStyle}>
        <style>
          {`
            /* Hide arrows in input of type number for WebKit browsers (Chrome, Safari) This was generated by a LLM.*/
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Hide arrows in input of type number for Firefox */
            input[type="number"] {
                -moz-appearance: textfield;
            }
          `}
        </style>
        <div style={rowStyle}>
          <span style={{ color: COLOR_DIVISOR }}>{multiplicand}</span>
          &nbsp;x&nbsp;
          <input
            type="number"
            disabled={disableInput}
            value={inputMultiplier}
            onChange={(e) => setInputMultiplier(e.target.value)}
            style={{
              ...inputStyle,
              backgroundColor:
                highlightedInput === 1 ? COLOR_HIGHLIGHTED : COLOR_MEDIUM,
            }}
          />
          &nbsp;+&nbsp;
          <input
            type="number"
            disabled={disableInput}
            value={inputRemainder1}
            onChange={(e) => setInputRemainder1(e.target.value)}
            style={{
              ...inputStyle,
              backgroundColor:
                highlightedInput === 2 ? COLOR_HIGHLIGHTED : COLOR_MEDIUM,
            }}
          />
          &nbsp;=&nbsp;{multiplicand * multiplier + remainder}
        </div>
        <div style={rowStyle}>
          {multiplicand * multiplier + remainder} :&nbsp;
          <span style={{ color: COLOR_DIVISOR }}>{multiplicand}</span>{" "}
          &nbsp;=&nbsp;
          <input
            type="number"
            disabled={disableInput}
            value={inputQuotient}
            onChange={(e) => setInputQuotient(e.target.value)}
            style={{
              ...inputStyle,
              backgroundColor:
                highlightedInput === 1 ? COLOR_HIGHLIGHTED : COLOR_MEDIUM,
            }}
          />
          &nbsp;{InputBoxRemainder}&nbsp;
          <input
            type="number"
            disabled={disableInput}
            value={inputRemainder2}
            onChange={(e) => setInputRemainder2(e.target.value)}
            style={{
              ...inputStyle,
              backgroundColor:
                highlightedInput === 2 ? COLOR_HIGHLIGHTED : COLOR_MEDIUM,
            }}
          />
        </div>
        <button style={buttonStyle} onClick={checkAnswers}>
          {buttonText}
        </button>
        {message && <div style={messageStyle}>{message}</div>}
      </div>
    </div>
  );
}

export default Level6;
