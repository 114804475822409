import React from "react";
import {
  COLOR_MEDIUM,
  COLOR_VERYLIGHT,
  FONT,
  NUMBER_OF_LEVELS,
} from "./defaults";
function Navbar({ level, setLevel, progress }) {
  function handleDotClick(level) {
    changeLevel(level);
  }

  function changeLevel(level) {
    if (level <= progress) {
      setLevel(level);
    }
  }

  return (
    <div
      style={{
        backgroundColor: "#262626",
        height: "1.5dvh",
        width: "80%",
        position: "relative",
        margin: "4dvh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "1dvh",
      }}
    >
      {Array.from({ length: NUMBER_OF_LEVELS }).map((_, index) => (
        <button
          key={index}
          onClick={() => handleDotClick(index)}
          style={{
            width: "8dvh",
            height: "8dvh",
            borderRadius: "50%",
            background:
              index === level
                ? COLOR_VERYLIGHT
                : index <= progress
                ? COLOR_MEDIUM
                : "#262626",
            border: "none",
            cursor: index <= progress ? "pointer" : "not-allowed",
            alignSelf: "center",
            boxShadow:
              index === progress
                ? "0 0 10px 5px white"
                : "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
          }}
        >
          {index > progress && (
            <span
              style={{
                position: "relative",
                top: "-5%",
                left: "0%",
                pointerEvents: "none",
                fontSize: "2em",
              }}
            >
              🔒
            </span>
          )}
          {index === level && (
            <span
              style={{
                position: "relative",
                top: "-5%",
                left: "0%",
                pointerEvents: "none",
                fontSize: "2em",
              }}
            >
              ⚓
            </span>
          )}
          {index <= progress && index !== level && (
            <span
              style={{
                position: "relative",
                fontSize: "3dvh",
                fontFamily: FONT,
                color: "white",
                textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
              }}
            >
              {index + 1}
            </span>
          )}
        </button>
      ))}
    </div>
  );
}

export default Navbar;
