import React from "react";
import { useDrag } from "react-dnd";
import defaultCoin from "../images/coin1.svg";

/*
This class implements a coin that can be dragged.
If no image is provided, the defaultCoin is used.
Parts of it were created with the help of LLMs.
*/
function Coin({ id, coinImage = defaultCoin }) {
  const [{ isDragging }, drag] = useDrag({
    type: "coin",
    item: { id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        width: "5dvh",
        height: "5dvh",
      }}
    >
      <img
        src={coinImage}
        alt="coin"
        style={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5))",
        }}
      />
    </div>
  );
}

export default Coin;
