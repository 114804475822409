import React, { useState, useEffect } from "react";
import RopePiece from "../components/RopePiece";
import Scissors from "../components/Scissors";
import InputBox from "../components/InputBox";
import ProgressPopup from "../components/ProgressPopup";
import { COLOR_DIVISOR, FONT, increaseProgress, REPEATS } from "../defaults";
import {
  level0Correct,
  level3Incorrect1,
  level3Incorrect2,
  level4Question,
  level3Welcome1,
  level3Welcome2,
  level3Welcome3,
  level4Incorrect,
  nextLevelUnlocked,
  level3Incorrect3,
} from "../strings";

const LevelNumber = 4;

function calculatePieceCounts(currentProgress) {
  const numbers = [3, 6, 3];
  if (currentProgress >= numbers.length) {
    return Math.ceil(Math.random() * 4) + 1;
  }
  return numbers[currentProgress];
}

function calculatePieceSizes(pieceCount, currentProgress) {
  const numbers = [7, 2, 3];
  if (currentProgress >= numbers.length) {
    return Math.floor(Math.random() * (5 - pieceCount / 2)) + 2;
  }
  return numbers[currentProgress];
}

function calculateRemainders(pieceSize, currentProgress) {
  const numbers = [3, 1, 2];
  if (currentProgress >= numbers.length) {
    return Math.ceil(Math.random() * (pieceSize - 1));
  }
  return numbers[currentProgress];
}

function Level4({ levelProgress, setLevelProgress }) {
  const [scissorProgress, setScissorProgress] = useState(0);

  const [pieceCount, setPieceCount] = useState(
    calculatePieceCounts(levelProgress[LevelNumber])
  );
  const [pieceSize, setPieceSize] = useState(
    calculatePieceSizes(pieceCount, levelProgress[LevelNumber])
  );
  const [remainder, setRemainder] = useState(
    calculateRemainders(pieceSize, levelProgress[LevelNumber])
  );

  useEffect(() => {
    const pieceC = calculatePieceCounts(levelProgress[LevelNumber]);
    const pieceS = calculatePieceSizes(pieceC, levelProgress[LevelNumber]);
    setPieceCount(pieceC);
    setPieceSize(pieceS);
    setRemainder(calculateRemainders(pieceS, levelProgress[LevelNumber]));
    setFallingPieces(Array(pieceC).fill(false));
    setScissorProgress(0);
  }, [levelProgress]);

  const [fallingPieces, setFallingPieces] = useState(
    Array(pieceCount).fill(false)
  );

  const handleScissorsClick = (index) => {
    if (scissorProgress === index) {
      const newFallingPieces = [...fallingPieces];
      newFallingPieces[index] = true;
      setFallingPieces(newFallingPieces);
      setScissorProgress((prev) => prev + 1);
    }
  };

  const getFallHeight = (index) => {
    return index % 2 === 0 ? 16 : 13;
  };

  const numberLineValues = Array.from(
    { length: pieceCount * pieceSize + remainder + 1 },
    (_, index) => index
  );

  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCorrect = () => {
    if (levelProgress[LevelNumber] + 1 === REPEATS) {
      setMessage(level0Correct + nextLevelUnlocked);
    } else {
      setMessage(level0Correct);
    }
    setShowModal(true);
    increaseProgress(levelProgress, setLevelProgress, LevelNumber);
  };

  const handleIncorrect = () => {
    setMessage(
      level3Incorrect1 +
        (scissorProgress === pieceCount ? "" : level3Incorrect2) +
        "\n" +
        (pieceCount * pieceSize + remainder) +
        ":" +
        pieceSize +
        level3Incorrect3 +
        pieceSize +
        "." +
        level4Incorrect
    );
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div
      style={{ position: "relative", marginTop: "2dvh", marginBottom: "1dvh" }}
    >
      <div
        className="Welcome-label"
        style={{ fontFamily: FONT, fontSize: "3.5dvh" }}
      >
        {level3Welcome1}
        <span style={{ color: COLOR_DIVISOR }}>{pieceSize}</span>
        {level3Welcome2}
        {pieceCount * pieceSize + remainder}?<br></br>
        {level3Welcome3}
      </div>
      <ProgressPopup show={showModal} handleClose={hideModal}>
        {message}
      </ProgressPopup>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "4dvh",
          marginBottom: "1dvh",
        }}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
            alignItems: "start",
            height: "5dvh",
          }}
        >
          {Array.from({ length: pieceCount }).map((_, index) => (
            <React.Fragment key={index}>
              <RopePiece
                pieceSize={pieceSize}
                isFalling={fallingPieces[index]}
                fallHeight={fallingPieces[index] ? getFallHeight(index) : 0}
                showDot={index === scissorProgress}
              />
              {index < pieceCount && (
                <div style={{ position: "relative", width: `0px` }}>
                  <Scissors
                    onClick={() => handleScissorsClick(index)}
                    visibility={scissorProgress === index ? 1 : 0}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
          <RopePiece
            pieceSize={remainder}
            isFalling={false}
            fallHeight={0}
            showDot={scissorProgress === pieceCount}
          />
        </div>
        <div
          className="number-line"
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: `${pieceCount * pieceSize * 5 + 5}dvh`,
            height: "6dvh",
            marginTop: "1dvh",
          }}
        >
          <hr
            className="line"
            style={{
              position: "absolute",
              top: "50%",
              width: `${(remainder + pieceCount * pieceSize) * 5}dvh`,
              height: "0.3dvh",
              backgroundColor: "black",
              border: "0.15dvh solid black",
            }}
          ></hr>
          <div style={{ display: "flex" }}>
            {numberLineValues.map((value, index) => (
              <div
                key={index}
                className="tick"
                style={{
                  position: "relative",
                  height: "100%",
                  width: "5dvh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="number"
                  style={{
                    fontFamily: FONT,
                    fontSize: "3dvh",
                    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {value}
                </div>
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "2px",
                    height: "15px",
                    backgroundColor: "black",
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {scissorProgress === pieceCount && (
        <div style={{ marginTop: "10dvh" }}>
          <span
            className="text-wrapper"
            style={{
              fontFamily: FONT,
              color: "white",
              fontSize: "3dvh",
              textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
            }}
          >
            {level4Question}
          </span>
          <div style={{ height: "1dvh" }}></div>
          <InputBox
            handleCorrect={handleCorrect}
            handleIncorrect={handleIncorrect}
            dividend={pieceCount * pieceSize + remainder}
            divisor={pieceSize}
            remainder={remainder}
          >
            {" "}
          </InputBox>
        </div>
      )}
    </div>
  );
}

export default Level4;
