import "./App.css";
import Navbar from "./navbar";
import Level from "./level";
import React, { useState, useEffect } from "react";
import {
  COLOR_LIGHT,
  COLOR_MEDIUM,
  FONT,
  NUMBER_OF_LEVELS,
  REPEATS,
} from "./defaults";
import shippy from "./images/ship.png";
import { AppLevelNames, AppRotateScreen, AppScreenSize } from "./strings";

const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [parseInt(value), setValue];
};

const useLocalStorageProgress = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    if (
      storedValue !== null &&
      JSON.parse(storedValue).map(Number).length < NUMBER_OF_LEVELS
    ) {
      return initialValue;
    }
    return storedValue !== null
      ? JSON.parse(storedValue).map(Number)
      : initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

function App() {
  const [level, setLevel] = useLocalStorage("level", 0);
  const [progress, setProgress] = useLocalStorage("progress", 0);
  const [levelProgress, setLevelProgress] = useLocalStorageProgress(
    "levelProgress",
    Array(NUMBER_OF_LEVELS).fill(0)
  );
  const [tutorialProgress, setTutorialProgress] = useLocalStorageProgress(
    "tutorialProgress",
    Array(NUMBER_OF_LEVELS).fill(0)
  );

  /*
function App() {

  const [level, setLevel] = useState(0);
  const [progress, setProgress] = useState(0);
  const [levelProgress, setLevelProgress] = useState(Array(NUMBER_OF_LEVELS).fill(0));
*/
  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const [isScreenPortrait, setIsScreenPortrait] = useState(false);

  const checkScreenSize = () => {
    if (window.innerWidth < 600 || window.innerHeight < 360) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
    if (window.innerWidth < window.innerHeight) {
      setIsScreenPortrait(true);
    } else {
      setIsScreenPortrait(false);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const goPreviousLevel = () => {
    if (level > 0) {
      setLevel((prev) => prev - 1);
    }
  };

  const goNextLevel = () => {
    if (level < progress) {
      setLevel((prev) => prev + 1);
    }
  };

  return (
    <div className="App">
      <header className="App-header" style={{ backgroundColor: COLOR_LIGHT }}>
        {isScreenSmall ? (
          <h1 style={{ fontFamily: FONT }}>{AppScreenSize}</h1>
        ) : isScreenPortrait ? (
          <h1 style={{ fontFamily: FONT }}>{AppRotateScreen}</h1>
        ) : (
          <>
            <div className="Level-label" style={{ fontFamily: FONT }}>
              {AppLevelNames[level]
                ? AppLevelNames[level]
                : "Level not implemented yet"}
            </div>
            <img
              src={shippy}
              alt="shippy"
              className="shippy"
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTutorialProgress((prev) =>
                  prev.map((item, index) => (index === level ? 0 : item))
                )
              }
            />
            {level > 0 && (
              <button
                onClick={goPreviousLevel}
                className="navigation-button"
                style={{
                  position: "absolute",
                  left: "5vw",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <i className="fas fa-chevron-left"></i>
              </button>
            )}
            <div
              className="Task"
              style={{ backgroundColor: COLOR_MEDIUM, width: "80%" }}
            >
              <Level
                level={level}
                setLevel={setLevel}
                progress={progress}
                setProgress={setProgress}
                levelProgress={levelProgress}
                setLevelProgress={setLevelProgress}
                tutorialProgress={tutorialProgress}
                setTutorialProgress={setTutorialProgress}
              />
            </div>
            {level < progress && (
              <button
                onClick={goNextLevel}
                className="navigation-button"
                style={{
                  position: "absolute",
                  right: "5vw",
                  transform: "translate(50%, -50%)",
                }}
              >
                <i className="fas fa-chevron-right"></i>
              </button>
            )}
            <div style={{ display: "flex", width: "87%", marginLeft: "13%" }}>
              <Navbar level={level} setLevel={setLevel} progress={progress} />
              <div
                className="Progress-label"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: FONT,
                  textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
                }}
              >
                {levelProgress[level] < REPEATS
                  ? levelProgress[level] + "/" + REPEATS
                  : "Fertig"}
              </div>
            </div>
          </>
        )}
      </header>
    </div>
  );
}

export default App;
