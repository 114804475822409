const userLang = navigator.language || navigator.userLanguage;
let lang = 1;
if (userLang.startsWith("en")) {
  lang = 0;
} else if (userLang === "de") {
  lang = 1;
}

// App.jsx
export const AppScreenSize = [
  "Your screen size is too small. Please use a larger screen to access this website.",
  "Dein Bildschirm ist zu klein. Bitte nutze einen grösseren Bildschirm.",
][lang];
export const AppRotateScreen = [
  "Please rotate your screen.",
  "Bitte drehe deinen Bildschirm.",
][lang];
export const AppLevelNames = [
  [
    "Pirates need to share",
    "Are there enough coins?",
    "What shall we do with the remainder?",
    "Cut the ropes",
    "Ropes with a remainder",
    "Dividing by multiplying",
    "Dividing by multiplying with a remainder",
    "Division with big coins",
    "Bigger coins with a remainder",
    "Grouped coins",
    "Two digit divisors",
    "Expanded division",
    "Tabel division",
    "Work in progress",
  ],
  [
    "Piraten müssen teilen",
    "Haben wir genügend Münzen?",
    "Was tun mit dem Rest?",
    "Schnüre schneiden",
    "Schnüre mit Rest",
    "Teilen mit Multiplizieren",
    "Teilen mit Multiplizieren und Rest",
    "Teilen mit grossen Münzen",
    "Grosse Münzen mit Rest",
    "Gruppierte Münzen",
    "Zweistellige Divisoren",
    "Halbschriftliche Division",
    "Tabellen Division",
    "Work in progress",
  ],
][lang];

// InputBox.jsx
export const InputBoxResult = ["Result", "Resultat"][lang];
export const InputBoxRemainder = ["Remainder", "Rest"][lang];
export const InputBoxCheck = ["Check", "Prüfen"][lang];

// ProgressPopup.jsx
export const ProgressPopupOkay = ["Okay", "Okay"][lang];
export const ProgressPopupSkip = ["Skip tutorial", "Tutorial Überspringen"][
  lang
];

// Level0.jsx
export const level0Welcome = [
  "Distribute the coins to the chests in turn.",
  "Verteile die Münzen der Reihe nach auf die Truhen.",
][lang];
export const level0Question = [
  "How many coins does each pirate get?",
  "Wie viele Münzen bekommt jeder Pirat?",
][lang];
export const level0WrongChest = [
  "Wrong chest!\nDistribute the coins in turn and in order.",
  "Falsche Truhe!\nVerteile die Münzen abwechslungsweise und der Reihe nach.",
][lang];
export const level0Incorrect = [
  "Not quite right.\nThe result is the number of coins in each chest.",
  "Nicht ganz richtig.\nDas Resultat ist die Anzahl Münzen in jeder Truhe.",
][lang];
export const level0Correct = ["Correct, well done!", "Richtig, gut gemacht!"][
  lang
];
export const nextLevelUnlocked = [
  "\nYou've unlocked the next level!",
  "\nDu hast das nächste Level freigeschaltet!",
][lang];

// Level1.jsx
export const level1Welcome = [
  "Count the coins and chests.",
  "Zähle die Münzen und Truhen.",
][lang];
export const Level1True = ["Yes", "Ja"][lang];
export const Level1False = ["No", "Nein"][lang];
export const level1Question = [
  "Are there enough coins for a division round?",
  "Gibt es genügend Münzen, um sie zu verteilen?",
][lang];
export const level1IncorrectYes = [
  'Not quite right.\nWhen there are more or the same number of coins as chests,\nthey can be distributed. Therefore, click "Yes".',
  'Nicht ganz richtig.\nWenn es mehr oder gleich viele Münzen wie Truhen gibt,\nkann man sie verteilen. Also drücke "Ja".',
][lang];
export const level1IncorrectNo = [
  'Not quite right.\nYou can not divide the coins if there are less coins than chests.\nTherefore, click "No"',
  'Nicht ganz richtig.\nWenn es weniger Münzen als Truhen gibt,\nkann man die Münzen\nnicht verteilen. Also drücke "Nein".',
][lang];

// Level2.jsx
export const level2Welcome1 = ["What is ", "Was gibt "][lang];
export const level2Welcome2 = [" divided by ", " geteilt durch "][lang];
export const level2Welcome3 = [
  "Distribute the coins to find out!",
  "Verteile die Münzen und finde es heraus!",
][lang];
export const level2NotEnoughCoins = [
  "You do not have enough coins left for all chests.\nWrite down the result.",
  "Du hast nicht mehr genügend Münzen für alle Truhen.\nSchreibe das Resultat auf.",
][lang];
export const level2Incorrect =
  level0Incorrect +
  [
    "\nThe remainder is the amount of left over coins.",
    "\nDer Rest ist die Anzahl übriger Münzen.",
  ][lang];

// Level3.jsx
export const level3Incorrect1 = ["Not quite right.", "Nicht ganz richtig."][
  lang
];
export const level3Incorrect2 = [" Cut the rope.", " Zerschneide die Schnur."][
  lang
];
export const level3Incorrect3 = [
  " is the amount of rope pieces of length ",
  " ist die Anzahl gleich grosser Schnurstücke der Länge ",
][lang];
export const level3Welcome1 = ["How many times does ", "Wie oft passt "][lang];
export const level3Welcome2 = [" fit into ", " in "][lang];
export const level3Welcome3 = [
  "Click the scissors to find out.",
  "Klicke auf die Schere und finde es heraus.",
][lang];
export const level3Question = [
  "How many rope pieces are there?",
  "Wie viele Schnurstücke gibt es?",
][lang];

// Level4.jsx
export const level4Incorrect = [
  "\nThe remainder is the length of the leftover rope.",
  "\nDer Rest ist die Grösse der übrigen Schnur.",
][lang];
export const level4Question =
  level3Question +
  [
    "\nThe remainder is the length of the remaining rope",
    "\nDer Rest ist die Länge der übrig gebliebenen Schnur.",
  ][lang];

// Level5.jsx
export const level5Numbers = [
  "Enter all numbers first.",
  "Gib zuerst alle Zahlen ein.",
][lang];
export const level5EqualResults = [
  "Your results must be the same!",
  "Deine Resultate müssen gleich sein!",
][lang];
export const level5Welcome = [
  "Solve the multiplication riddle to find out.",
  "Löse das Multiplikationrätsel und finde es heraus.",
][lang];

// Level6.jsx
export const level6EqualRemainders = [
  "The remainders must be the same!",
  "Die Reste müssen übereinstimmen!",
][lang];
export const level6RemainderSmaller1 = [
  "The remainder has to be smaller than the ",
  "Der Rest muss kleiner als der ",
][lang];
export const level6RemainderSmaller2 = ["divisor", "Divisor"][lang];
export const level6RemainderSmaller3 = [".", " sein."][lang];

// Level7.jsx
export const level7NotEnoughCoins = [
  "You do not have enough of these coins!",
  "Du hast nicht mehr genug von diesen Münzen!",
][lang];
export const level7ExchangeCoins =
  level7NotEnoughCoins.slice(0, -1) +
  [", exchange them!", ", tausche sie um!"][lang];
export const level7WrongBox = [
  "Drop the coin into another box.",
  "Gib erst ein mal den anderen Boxen mehr Münzen.",
][lang];
export const level7Exchange1 = [
  "These coins are too small to be exchanged!",
  "Diese Münzen sind zu klein um sie zu tauschen!",
][lang];
export const level7BiggerFirst = [
  "Distribute the bigger coins first!",
  "Verteile zu erst die grösseren Münzen!",
][lang];
export const level7TooMany = [
  "You still have too many coins to exchange them!",
  "Du hast noch zu viele Münzen um zu tauschen!",
][lang];
export const level7WrongNotDistributed =
  level3Incorrect1 + ["Distribute the coins!", "Verteile die Münzen!"][lang];
export const level7WrongDistributed =
  level3Incorrect1 +
  [
    " The result is the sum of the coins in one Box.\n The remainder is what's left over.",
    " Das Resultat ist die Summe der Münzen in jeder Box.\n Der Rest ist was übrig bleibt.",
  ][lang];
export const level7Welcome = [
  "Distribute the coins equaly between the boxes to find out.",
  "Verteile die Münzen gleichmässig auf alle Boxen und finde es heraus.",
][lang];
export const level7Exchange = ["Exchange", "Tauschen"][lang];

// Level9.jsx
export const level9Welcome = [
  "Distribute the coins to find out.",
  "Verteile die Münzen und finde es heraus.",
][lang];

// Level12.jsx
export const level12FillFields = [
  "\n Fill in all fields to get the solution.",
  "\nFülle alle Felder aus, um das Resultat zu erhalten.",
][lang];
export const level12action = ["Action:", "Aktion:"][lang];

// Tutorial0.jsx
export const Tutorial0Continue = ["Continue", "Weiter"][lang];
export const Tutorial0Welcome1 = ["Welcome to the ", "Willkommen beim "][lang];
export const Tutorial0Welcome2 = ["first ", "ersten "][lang];
export const Tutorial0Welcome3 = [
  'tutorial.\nClick "Okay" when you are ready.',
  'Tutorial.\nDrücke auf "Okay", wenn du bereit bist.',
][lang];
export const Tutorial0Welcome =
  Tutorial0Welcome1 + Tutorial0Welcome2 + Tutorial0Welcome3;
export const Tutorial0Description1 = [
  "On this website you can learn how division works.",
  "Auf dieser Webseite kannst du lernen, wie Dividieren funktioniert.",
][lang];
export const Tutorial0Description2 = [
  "Before playing each level, there will be a short tutorial. If you want to see it again, click the ship on the top right.",
  "Vor jedem neuen Level gibt es ein kleines Tutorial. Falls du ein Tutorial erneut spielen willst, drücke auf das Schiff oben rechts.",
][lang];
export const Tutorial0Description3 = [
  "Let's start!",
  "Fangen wir mit dem Ersten an!",
][lang];
export const Tutorial0Description4 = [
  "In this level, you have to distribute the coins\nequally and in order. Like this:",
  "In diesem Level musst du die Münzen der Reihe nach\n auf die Truhen verteilen. Etwa so:",
][lang];
export const Tutorial0Description5 = [
  "Afterwards, count the coins in a chest and enter\nthe number into the field below.",
  "Gib anschliessend die Anzahl Münzen in jeder Truhe\nin das Feld unten ein",
][lang];
export const Tutorial0Description6 = [
  "Well done!\nNow solve the level on your own.",
  "Gut gemacht!\nLöse das Level nun selber.",
][lang];

// Tutorial1.jsx
export const Tutorial1Welcome =
  Tutorial0Welcome1 + ["second ", "zweiten "][lang] + Tutorial0Welcome3;
export const Tutorial1Description1 = [
  "In this level, you have to decide if there are\nenough coins for a distribution round.",
  "In diesem Level musst du entscheiden, ob es\ngenügend Münzen für eine Verteilrunde gibt.",
][lang];
export const Tutorial1Description2 = [
  'If there are more or the same amount of coins as chests,\nyou have to answer with "Yes".',
  'Wenn es mehr oder gleichviele Münzen wie Truhen gibt,\nmusst du mit "Ja" antworten.',
][lang];
export const Tutorial1Description3 = [
  'If there are less coins than chests,\nyou have to answer with "No".',
  'Wenn es weniger Münzen als Truhen gibt,\nmusst du mit "Nein" antworten.',
][lang];
export const Tutorial1Description4 = [
  "Now try solving the level!",
  "Versuche jetzt das Level zu lösen!",
][lang];

// Tutorial2.jsx
export const Tutorial2Welcome =
  Tutorial0Welcome1 + ["third ", "dritten "][lang] + Tutorial0Welcome3;
export const Tutorial2Description1 = [
  "In this level, you have to distribute the coins again,\nin order. Like this:",
  "In diesem Level musst du die Münzen wieder der Reihe nach\n auf die Truhen verteilen. Etwa so:",
][lang];
export const Tutorial2Description2 = [
  'As you can see, the last two coins can not be distributed.\nThey are called the "remainder".',
  'Wie du siehst, kann man hier die letzten zwei Münzen nicht\nmehr verteilen. Mann nennt sie den "Rest".',
][lang];
export const Tutorial2Description3 = [
  "Now enter the number of coins in each chest into the first field.\nWrite down the remainder in the second field.",
  "Gib nun die Anzahl Münzen in jeder Truhe in das erste Feld unten ein.\nIn das zweite Feld kommt der Rest.",
][lang];

// Tutorial3.jsx
export const Tutorial3Welcome =
  Tutorial0Welcome1 + ["fourth ", "vierten "][lang] + Tutorial0Welcome3;
export const Tutorial3Description1 = [
  "In this level, you have to click the scissors\nto cut the rope into equally sized pieces. Like this:",
  "In diesem Level musst du auf die Schere drücken,\num die Schnur in gleich grosse Stücke zu zerschneiden. Etwa so:",
][lang];
export const Tutorial3Description2 = [
  "Now answer the question:",
  "Beantworte anschliessend die Frage:",
][lang];

// Tutorial4.jsx
export const Tutorial4Welcome =
  Tutorial0Welcome1 + ["fifth ", "fünften "][lang] + Tutorial0Welcome3;
export const Tutorial4Description1 = [
  "In this level, you again have to click the scissors\nto cut the rope into equally sized pieces. Like this:",
  "In diesem Level musst du wieder auf die Schere drücken,\num die Schnur in gleich grosse Stücke zu zerschneiden. Etwa so:",
][lang];
export const Tutorial4Description2 = [
  "The left over rope is too short to be divided.\nTherefore it is left as the remainder.",

  "Die übrig gebliebene Schnur ist zu kurz zum Teilen.\nAlso bleibt sie als Rest.",
][lang];

// Tutorial5.jsx
export const Tutorial5Welcome =
  Tutorial0Welcome1 + ["sixth ", "sechsten "][lang] + Tutorial0Welcome3;
export const Tutorial5Description1 = [
  "Division is closely related to multiplication.\nTherefore we can view division as a multiplication riddle.",
  "Die Division ist stark mit dem Multiplizieren verwandt.\nDarum kann man Teilen auch als Multiplikationsrätsel sehen.",
][lang];
export const Tutorial5Description2 = [
  "As you surely know, 3 x 4 = 12\nYou can use this knowledge, to solve 12 : 3.",
  "Wie du sicher weisst, gibt 3 x 4 = 12.\nDieses Wissen kannst du nutzen, um 12 : 3 zu lösen.",
][lang];
export const Tutorial5Description3 = [
  "Since 3 x 4 = 12, we know that 12 : 3 = 4.",
  "Weil 3 x 4 = 12, gilt 12 : 3 = 4.",
][lang];
export const Tutorial5Description4 = [
  "Now solve the level yourself! What's 12 divided by 3?",
  "Löse nun das Level! Was gibt 12 geteilt durch 3?",
][lang];

// Tutorial6.jsx
export const Tutorial6Welcome =
  Tutorial0Welcome1 + ["seventh ", "siebten "][lang] + Tutorial0Welcome3;
export const Tutorial6Description1 = [
  "You can divide by solving a multiplication riddle,\neven if there is a remainder.",
  "Auch wenn es einen Rest gibt, kann man Teilen,\nindem man ein Multiplikationsrätsel löst.",
][lang];
export const Tutorial6Description2 = [
  "Firstly, you have to find the biggest number that,\nmultiplied by 3, is still smaller than 14.",
  "Suche zuerst die grösste Zahl, die mit 3 multipliziert\nnoch kleiner als 14 ist.",
][lang];
export const Tutorial6Description3 = [
  "3 x 4 = 12 is still smaller than 14, but 3 x 5 = 15 is too big.\nTherefore, we choose 4.",
  "3 x 4 = 12 ist kleiner als 14, aber 3 x 5 = 15 ist zu gross.\nAlso nehmen wir 4.",
][lang];
export const Tutorial6Description4 = [
  "To get to 14, we have to add 2. Therefore,\n3 x 4 + 2 = 14.",
  "Um auf 14 zu kommen, müssen wir noch 2 dazu rechnen.\nAlso gilt 3 x 4 + 2 = 14.",
][lang];
export const Tutorial6Description5 = [
  "Since 3 x 4 + 2 = 14, we know that 14 : 3 = 4, remainder 2.",
  "Weil 3 x 4 + 2 = 14, gilt 14 : 3 = 4, Rest 2.",
][lang];
export const Tutorial6Description6 = [
  "Now solve the level yourself! What's 14 divided by 3?",
  "Löse nun das Level! Was gibt 14 geteilt durch 3?",
][lang];

// Tutorial7.jsx
export const Tutorial7Welcome =
  Tutorial0Welcome1 + ["eighth ", "achten "][lang] + Tutorial0Welcome3;
export const Tutorial7Description1 = [
  "In this level, you have to distribute the coins like in the first level.",
  "In diesem Level musst du die Münzen wieder wie im ersten Level verteilen.",
][lang];
export const Tutorial7Description2 = [
  "Start with the higher value coins first. Like this:",
  "Beginne zuerst mit den Münzen mit dem grössten Wert. Etwa so:",
][lang];
export const Tutorial7Description3 = [
  'Now there are not enough coins left for a distribution round.\nTherefore you have to click "Exchange", to change the 10er coins to 1s',
  'Jetzt gibt es zu wenige Münzen für eine Verteilrunde.\nDrücke also auf "Tauschen", um die 10er Münzen in 1er zu tauschen.',
][lang];
export const Tutorial7Description4 = [
  "Now we can continue distributing:",
  "Nach dem Tauschen können wir weiter verteilen:",
][lang];
export const Tutorial7Description5 = [
  "The result is the value of the coins in one box,\nthe remainder is the remaining coin. Now enter the solution!",
  "Das Resultat ist der Wert der Münzen in einer Box,\n der Rest ist die übrige Münze. Gib jetzt die Lösung ein!",
][lang];

// Tutorial8.jsx
export const Tutorial8Welcome =
  Tutorial0Welcome1 + ["nineth ", "neunten "][lang] + Tutorial0Welcome3;
export const Tutorial8Description1 = [
  "This level works similar to the previous one.\nBut now you have some help.",
  "Dieses Level funktioniert fast gleich wie das letzte.\nAb jetzt hast du aber eine kleine Hilfe.",
][lang];
export const Tutorial8Description2 = [
  "When you distribute one coin, the whole distribution round is done for you:",
  "Verteilst du eine Münze, wird die ganze Verteilrunde ausgeführt:",
][lang];

// Tutorial9.jsx
export const Tutorial9Welcome =
  Tutorial0Welcome1 + ["tenth ", "zehnten "][lang] + Tutorial0Welcome3;
export const Tutorial9Description1 = [
  "This level works similar to the previous one.\nNow, the coins are grouped.",
  "Dieses Level funktioniert fast gleich wie das letzte.\nAb jetzt sind die Münzen gruppiert.",
][lang];
export const Tutorial9Description2 = [
  "Distributing works like in the previous level:",
  "Das Verteilen funktioniert wie im Level davor:",
][lang];

// Tutorial10.jsx
export const Tutorial10Welcome =
  Tutorial0Welcome1 + ["eleventh ", "elften "][lang] + Tutorial0Welcome3;
export const Tutorial10Description1 = [
  "This level works similar to the previous one.\nNow the divisors has two digits.",
  "Dieses Level funktioniert fast gleich wie das letzte.\nJetzt sind die Divisoren zweistellig.",
][lang];
export const Tutorial10Description2 = [
  "This means that sometimes, like now, we have to exchange first.",
  "Das bedeutet, dass wir manchmal wie hier zu erst Tauschen müssen.",
][lang];
export const Tutorial10Description3 = [
  "After exchanging, we can distribute:",
  "Nach dem Tauschen können wir verteilen:",
][lang];
export const Tutorial10Description4 = [
  "Now we have to exchange again.",
  "Jetzt müssen wir wieder tauschen.",
][lang];
export const Tutorial10Description5 = [
  "Now we can continue distributing:",
  "Jetzt können wir weiter verteilen:",
][lang];
export const Tutorial10Description6 = [
  "The result is the value of the coins in the box,\nthe remainder are the remaining coins. Now enter the solution!",
  "Das Resultat ist der Wert der Münzen in der Box,\nder Rest sind die übrigen Münzen. Gib jetzt die Lösung ein!",
][lang];

// Tutorial11.jsx
export const Tutorial11Welcome =
  Tutorial0Welcome1 + ["twelfth ", "zwölften "][lang] + Tutorial0Welcome3;
const Tutorial11Enter = [
  "\nEnter the remainder on the left and the result on the right.",
  "\nGib den Rest links und das Resultat rechts ein.",
][lang];
export const Tutorial11Description1 = [
  "Here, you have to alternate between distributing and exchanging.",
  "Hier musst du abwechslungsweise Verteilen und Tauschen.",
][lang];
export const Tutorial11Description2 =
  [
    "We start by distributing. 7 : 3 = 2, remainder 1.",
    "Beginnen wir mit dem Verteilen. 7 : 3 = 2, Rest 1.",
  ][lang] + Tutorial11Enter;
export const Tutorial11Description3 = [
  "Now we have to exchange. 1 * 10 + 6 = 16.\nTherefore, you have to write down 16.",
  "Jetzt müssen wir Tauschen. 1 * 10 + 6 = 16.\nSchreibe also 16 in das Feld.",
][lang];
export const Tutorial11Description4 =
  [
    "Now we have to distribute again. 16 : 3 = 5, remainder 1.",
    "Jetzt müssen wir wieder Verteilen. 16 : 3 = 5, Rest 1.",
  ][lang] + Tutorial11Enter;
export const Tutorial11Description5 = [
  "Now we have to exchange. 1 * 10 + 5 = 15.\nTherefore, you have to write down 15.",
  "Jetzt müssen wir Tauschen. 1 * 10 + 5 = 15.\nSchreibe also 15 in das Feld.",
][lang];
export const Tutorial11Description6 =
  [
    "Now we have to distribute again. 15 : 3 = 5, remainder 0.",
    "Jetzt müssen wir wieder Verteilen. 15 : 3 = 5, Rest 0.",
  ][lang] + Tutorial11Enter;
export const Tutorial11Description7 = [
  "There is nothing left to exchange.\nTherefore, just write down 1.",
  "Jetzt müssen wir nichts Tauschen. \nSchreibe also einfach 1 in das Feld.",
][lang];
export const Tutorial11Description8 =
  ["1 : 3 = 0, remainder 1.", "1 : 3 = 0, Rest 1."][lang] + Tutorial11Enter;

// Tutorial12.jsx
export const Tutorial12Welcome =
  Tutorial0Welcome1 + ["last ", "letzten "][lang] + Tutorial0Welcome3;
export const Tutorial12Description1 = [
  'Here, you have to distribute and exchange again. From now on,\nsteps like "0 * 10 + 1 = 1" will be skipped.',
  'Hier musst du Verteilen und Tauschen. Ab jetzt werden\nSchritte, wie "0 * 10 + 1 = 1" übersprungen.',
][lang];
export const Tutorial12Description2 =
  [
    "Let's start with distributing. 7 : 3 = 2, remainder 1.",
    "Beginnen wir mit dem Verteilen. 7 : 3 = 2, Rest 1.",
  ][lang] + Tutorial11Enter;
export const Tutorial12Description3 = [
  "Now we have to exchange. 1 * 10 + 5 = 15.\nTherefore, write down 0 and 15.",
  "Jetzt müssen wir Tauschen. 1 * 10 + 5 = 15.\nSchreibe also 0 und 15 in die Felder.",
][lang];
export const Tutorial12Description4 =
  [
    "Now we have to distribute again. 15 : 3 = 5, remainder 0.",
    "Jetzt müssen wir wieder Verteilen. 15 : 3 = 5, Rest 0.",
  ][lang] + Tutorial11Enter;
export const Tutorial12Description5 = [
  "Since there is no remainder, we can continue distributing without exchanging.\n6 : 3 = 2, remainder 0.",
  "Da es keinen Rest gibt, können wir weiter Verteilen, ohne zu tauschen.\n6 : 3 = 2, Rest 0.",
][lang];
export const Tutorial12Description6 = [
  "Since there is no remainder left, and 1 is too small, we are done.\nNow enter the result into the field below!",
  "Weil es keinen Rest gab, und 1 zu klein ist, sind wir fertig.\nGib jetzt das Resultat unten ein!",
][lang];
