import React, { useState, useEffect } from "react";
import coin100 from "../images/coin100.svg";
import coin10 from "../images/coin10.svg";
import coin1 from "../images/coin1.svg";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import Box from "../components/Box";
import Coin from "../components/Coin";
import InputBox from "../components/InputBox";
import ProgressPopup from "../components/ProgressPopup";
import {
  COLOR_DIVISOR,
  COLOR_LIGHT,
  FONT,
  increaseProgress,
  REPEATS,
} from "../defaults";
import {
  level0Correct,
  level2Welcome1,
  level2Welcome2,
  level7BiggerFirst,
  level7Exchange,
  level7Exchange1,
  level7ExchangeCoins,
  level7NotEnoughCoins,
  level7TooMany,
  level7Welcome,
  level7WrongBox,
  level7WrongDistributed,
  level7WrongNotDistributed,
  nextLevelUnlocked,
} from "../strings";

const LevelNumber = 7;

function calculateDividend(currentProgress) {
  const numbers = [233];
  if (currentProgress >= numbers.length) {
    return Math.floor(Math.random() * 900) + 100;
  }
  return numbers[currentProgress];
}

function calculateDivisor(currentProgress) {
  const numbers = [2];
  if (currentProgress >= numbers.length) {
    return Math.floor(Math.random() * 4) + 2;
  }
  return numbers[currentProgress];
}

const coinImages = {
  0: coin100,
  1: coin10,
  2: coin1,
};

function Level7({ levelProgress, setLevelProgress }) {
  const [dividend, setDividend] = useState(
    calculateDividend(levelProgress[LevelNumber])
  );
  const [divisor, setDivisor] = useState(
    calculateDivisor(levelProgress[LevelNumber])
  );
  const [coins, setCoins] = useState([]);
  const [result, setResult] = useState([]);
  const [droppedCoins, setDroppedCoins] = useState([0, 0, 0]);
  const [coinsInBoxes, setCoinsInBoxes] = useState(
    Array.from({ length: 5 }, () => [0, 0, 0])
  );

  useEffect(() => {
    let divd = calculateDividend(levelProgress[LevelNumber]);
    let divr = calculateDivisor(levelProgress[LevelNumber]);
    let sum = Array.from(
      String(Math.floor(divd / divr)).padStart(3, "0"),
      Number
    ).reduce((partialSum, a) => partialSum + a, 0);
    while (sum * divr > 20) {
      divd = calculateDividend(levelProgress[LevelNumber]);
      divr = calculateDivisor(levelProgress[LevelNumber]);
      sum = Array.from(
        String(Math.floor(divd / divr)).padStart(3, "0"),
        Number
      ).reduce((partialSum, a) => partialSum + a, 0);
    }
    setDividend(divd);
    setDivisor(divr);
  }, [levelProgress]);

  useEffect(() => {
    setCoins(Array.from(String(dividend), Number));
    setResult(
      Array.from(
        String(Math.floor(dividend / divisor)).padStart(3, "0"),
        Number
      )
    );
    setDroppedCoins([0, 0, 0]);
    setCoinsInBoxes(Array.from({ length: 5 }, () => [0, 0, 0]));
  }, [dividend, divisor]);

  function handleDropCoin(item, boxIndex) {
    const allBoxesFilled = coinsInBoxes
      .slice(0, divisor)
      .every((box) => box[item[0]] >= result[item[0]]);
    if (allBoxesFilled && item[0] === 2) {
      setMessage(level7NotEnoughCoins);
      setShowModal(true);
    } else if (allBoxesFilled) {
      setMessage(level7ExchangeCoins);
      setShowModal(true);
    } else if (coinsInBoxes[boxIndex][item[0]] >= result[item[0]]) {
      setMessage(level7WrongBox);
      setShowModal(true);
    } else {
      const newDroppedCoins = [...droppedCoins];
      newDroppedCoins[item[0]] += 1;
      setDroppedCoins(newDroppedCoins);
      const newCoinsInBoxes = [...coinsInBoxes];
      newCoinsInBoxes[boxIndex][item[0]] += 1;
      setCoinsInBoxes(newCoinsInBoxes);
    }
  }

  function handleExchange(index) {
    if (index === 2) {
      setMessage(level7Exchange1);
      setShowModal(true);
    } else if (index === 1 && coins[0] !== droppedCoins[0]) {
      setMessage(level7BiggerFirst);
      setShowModal(true);
    } else if (
      coins[index] - droppedCoins[index] < divisor &&
      !coinsInBoxes
        .slice(0, divisor)
        .every((box) => box[index] === coinsInBoxes[0][index])
    ) {
      setMessage(level7BiggerFirst);
      setShowModal(true);
    } else if (coins[index] - droppedCoins[index] < divisor) {
      const newCoins = [...coins];
      newCoins[index] = droppedCoins[index];
      newCoins[index + 1] += (coins[index] - droppedCoins[index]) * 10;
      setCoins(newCoins);
    } else {
      setMessage(level7TooMany);
      setShowModal(true);
    }
  }

  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCorrect = () => {
    if (levelProgress[LevelNumber] + 1 === REPEATS) {
      setMessage(level0Correct + nextLevelUnlocked);
    } else {
      setMessage(level0Correct);
    }
    setShowModal(true);
    increaseProgress(levelProgress, setLevelProgress, LevelNumber);
  };

  const handleIncorrect = () => {
    let allBoxesFilled = coinsInBoxes
      .slice(0, divisor)
      .every((box) => box[0] >= result[0]);
    allBoxesFilled =
      allBoxesFilled &&
      coinsInBoxes.slice(0, divisor).every((box) => box[1] >= result[1]);
    allBoxesFilled =
      allBoxesFilled &&
      coinsInBoxes.slice(0, divisor).every((box) => box[2] >= result[2]);
    if (allBoxesFilled) {
      setMessage(level7WrongDistributed);
    } else {
      setMessage(level7WrongNotDistributed);
    }
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const exchangeStyle = {
    padding: "0.5dvh",
    margin: "0.5dvh",
    width: "10dvh",
    fontFamily: FONT,
    color: "white",
    fontSize: "2dvh",
    backgroundColor: COLOR_LIGHT,
    borderRadius: "0.25em",
    border: "none",
    boxShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    textShadow: "0.3svh 0.3svh 0.6svh rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  const coinsStyle = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    maxHeight: "50dvh",
  };

  return (
    <div>
      <div
        className="Welcome-label"
        style={{ fontFamily: FONT, fontSize: "3.5dvh" }}
      >
        {level2Welcome1}
        {dividend}
        {level2Welcome2}
        <span style={{ color: COLOR_DIVISOR }}>{divisor}</span>?<br></br>
        {level7Welcome}
      </div>
      <ProgressPopup show={showModal} handleClose={hideModal}>
        {message}
      </ProgressPopup>
      <DndProvider options={HTML5toTouch}>
        <div
          className="container"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: "50dvh",
              width: "30dvh",
            }}
          >
            {Array.from({ length: 3 }).map((_, id) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <button
                  disabled={coins[id] === droppedCoins[id]}
                  onClick={() => handleExchange(id)}
                  style={{
                    ...exchangeStyle,
                    opacity: coins[id] === droppedCoins[id] ? 0 : 1,
                    cursor: coins[id] === droppedCoins[id] ? "auto" : "pointer",
                  }}
                >
                  {level7Exchange}
                </button>
                <div style={coinsStyle}>
                  {Array.from({ length: coins[id] }).map(
                    (_, idx) =>
                      droppedCoins[id] <= idx && (
                        <Coin
                          key={[id, idx]}
                          id={[id, idx]}
                          coinImage={coinImages[id]}
                        />
                      )
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="right-panel">
            {Array.from({ length: divisor }).map((_, idx) => (
              <Box
                id={idx}
                onDropCoin={handleDropCoin}
                coins={coinsInBoxes[idx]}
              />
            ))}
          </div>
          <div className="equation" style={{ padding: "0.5dvh" }}>
            <InputBox
              handleCorrect={handleCorrect}
              handleIncorrect={handleIncorrect}
              dividend={dividend}
              divisor={divisor}
              remainder={dividend % divisor}
            ></InputBox>
          </div>
        </div>
      </DndProvider>
    </div>
  );
}

export default Level7;
